import * as React from 'react';

function SvgBlob2(props) {
  return (
    <svg viewBox="0 0 516 594" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity={0.6}
        d="M345.613 68.1c45.172 24.479 81.051 66.164 96.848 119.361 15.797 53.197 64.243 432.233-284.858 318.909-45.172-24.479-106.47-67.278-122.267-120.475-15.798-53.197 95.594-162.448 120.005-207.713 24.534-45.314 145.063-134.474 190.272-110.081z"
        fill="url(#blob2_svg__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="blob2_svg__paint0_linear"
          x1={490.845}
          y1={412.937}
          x2={122.935}
          y2={219.728}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#A4EEFF" />
          <stop offset={0.128} stopColor="#84DDF7" stopOpacity={0.872} />
          <stop offset={0.288} stopColor="#63CBF0" stopOpacity={0.712} />
          <stop offset={0.453} stopColor="#4ABDEA" stopOpacity={0.547} />
          <stop offset={0.622} stopColor="#37B3E5" stopOpacity={0.378} />
          <stop offset={0.8} stopColor="#2DADE3" stopOpacity={0.2} />
          <stop offset={1} stopColor="#29ABE2" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgBlob2;
