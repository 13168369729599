import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FirebaseContext } from 'context';
import { motion } from 'framer-motion';
import { DefaultCommenterAvatar } from 'assets/svgs';
import { CommenterAvatar } from 'components/Events/Livestream/Interaction/Chat';

const Participants = ({ colors, eventName, handleParticipantAvatarOrNameClick }) => {
  const { firebase } = useContext(FirebaseContext);
  const [lastFetchedParticipantDoc, setLastFetchedParticipantDoc] = useState();
  const [eventParticipants, setEventParticpants] = useState([]);

  const populateEventParticipantsList = (result) => {
    if (!result.empty) {
      setEventParticpants((currrentValue) => [
        ...currrentValue,
        ...result.docs
          // .filter(
          //   (doc) =>
          //     !doc.data().email.includes('agencyx') && !doc.data().email.includes('getbroadcasting')
          // )
          .map((doc) => {
            const {
              name,
              avatarUrl,
              workplaceName,
              linkedInProfile,
              sector,
              profession,
              eventRole,
              uid
            } = doc.data();
            return {
              name,
              avatarUrl,
              workplaceName,
              linkedInProfile,
              sector,
              profession,
              eventRole,
              uid
            };
          })
      ]);
      setLastFetchedParticipantDoc(result.docs[result.docs.length - 1]);
    }
  };

  useEffect(() => {
    firebase.fetchEventParticipants({ eventName }).then(populateEventParticipantsList);
    return () => {
      setEventParticpants([]);
      setLastFetchedParticipantDoc(null);
    };
  }, []);

  // TODO: Throttle/optimise this.
  const handleScrollParticipantsList = (e) => {
    e.persist();
    const participantsList = e.target;
    if (
      participantsList.scrollHeight - participantsList.scrollTop ===
      participantsList.clientHeight
    ) {
      firebase
        .fetchEventParticipants({ lastFetchedParticipantDoc })
        .then(populateEventParticipantsList);
    }
  };

  return (
    <Wrapper onScroll={handleScrollParticipantsList}>
      {eventParticipants.length > 0 &&
        eventParticipants.map((participant) => (
          <Participant
            onClick={() => handleParticipantAvatarOrNameClick(participant)}
            eventRole={participant.eventRole}
            colors={colors}
            key={participant.uid}>
            {participant.avatarUrl ? (
              <CommenterAvatar
                size="1.625rem"
                src={participant.avatarUrl}
                alt={participant.name}
                colors={colors}
              />
            ) : (
              <DefaultCommenterAvatar />
            )}
            <p>
              {participant.name}
              <span> - {participant.eventRole ? participant.eventRole : 'Attendee'}</span>
            </p>
          </Participant>
        ))}
    </Wrapper>
  );
};

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  background-color: #fff;
  padding: 1.25rem;
  min-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const Participant = styled.div`
  align-items: center;
  align-self: flex-start;
  color: #c4c4c4;
  display: flex;
  font-weight: 600;
  padding: 0.313rem 0 0.5em;

  img,
  svg {
    cursor: pointer;
    flex-shrink: 0;
    margin-right: 0.5rem;
    width: 1.625rem;
  }

  p {
    cursor: pointer;
  }

  span {
    color: ${({ eventRole, colors }) => (eventRole === 'Organiser' ? colors.secondary : '#000')};
    font-weight: ${({ eventRole }) => (eventRole === 'Organiser' ? 500 : 400)};
  }
`;

export default Participants;
