import React, { useContext } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Modal, SpeakerPopup } from 'components';
import { LocalContext } from 'context';
import { motion } from 'framer-motion';
import { useIntersection } from 'hooks';
import { breakpoints } from 'styles';
import {
  Blob1,
  Blob2,
  FbIcon,
  EmailIcon,
  LinkedinIcon,
  TwitterIcon
} from 'assets/svgs';

const Speaker = ({ data, buttonText, speakers, colors }) => {
  const { theme } = useContext(LocalContext);
  const speakerImage = getImage(data.image);
  const [elRef, observerEntry] = useIntersection({ threshold: 0 });

  return (
    <Modal
      trigger={
        <StyledCard
          $theme={theme}
          colors={colors}
          ref={elRef}
          initial={{ opacity: 0 }}
          animate={observerEntry.isIntersecting ? { opacity: 1 } : { opacity: 0 }}>
          <CardImgContainer>
            <SVG>
              <Blob1Styled $theme={theme} colors={colors} />
            </SVG>
            <SVG>
              <Blob2Styled $theme={theme} colors={colors} />
            </SVG>
            <CardImg image={speakerImage} alt={data.name} />
          </CardImgContainer>
          <CardTitle colors={colors}>{data.name}</CardTitle>
          <CardPosition dangerouslySetInnerHTML={{ __html: data.position.replace(', ', '<br />') }} />
          {/* <SpeakerSocials>
            <SpeakerSocial href={data.fbLink} $theme={theme} colors={colors}>
              <FbIcon />
            </SpeakerSocial>
            <SpeakerSocial href={data.linkedinLink} $theme={theme} colors={colors}>
              <LinkedinIcon />
            </SpeakerSocial>
            <SpeakerSocial href={data.twLink} $theme={theme} colors={colors}>
              <TwitterIcon />
            </SpeakerSocial>
            <SpeakerSocial href={data.emailLink} $theme={theme} colors={colors}>
              <EmailIcon />
            </SpeakerSocial>
          </SpeakerSocials> */}
          {/* <CardTopics>
            {data.topics.map((topic, i) => (
              <CardTopic key={i}>{topic}</CardTopic>
            ))}
          </CardTopics> */}
          <CardFooter>
            <div style={{ alignSelf: 'center' }}>
              <CardButton $theme={theme} colors={colors} hover>
                {buttonText}
              </CardButton>
            </div>
          </CardFooter>
        </StyledCard>
      }
      modalContent={<SpeakerPopup startPoint={data.speakerOrder - 1} speakers={speakers} colors={colors} />}
    />
  );
};

const CardImgContainer = styled(motion.div)`
  filter: none;
  left: 50%;
  max-width: 300px;
  overflow: visible;
  padding: 50px 20px 30px;
  position: relative;
  transform: translateX(-50%);
  transition-duration: 1s;
`;

const SVG = styled(motion.div)`
  bottom: 0;
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  transition: 1s;
  width: auto;
  svg {
    path {
      transition: 0.5s;
    }
  }
  @media (min-width: 1150px) {
    opacity: 0;
  }
`;

const Blob1Styled = styled(Blob1)`
  height: 100%;
  left: -8%;
  position: absolute;
  top: 0;
  transition-duration: 1s;
  width: 82.5%;
  path {
    ${({ $theme }) =>
      $theme.className === 'contrast' && `fill: ${$theme.textColor} !important;`}
  }
`;

const Blob2Styled = styled(Blob2)`
  bottom: 10%;
  height: 85%;
  position: absolute;
  right: -8%;
  transition-duration: 1s;
  width: 85%;
  path {
    ${({ $theme }) =>
      $theme.className === 'contrast' && `fill: ${$theme.textColor} !important;`}
  }
`;

const CardImg = styled(GatsbyImage)`
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 1.25rem;
  object-fit: cover;
  object-position: center;
  transition: 0.5s;
`;

const CardTitle = styled.h3`
  color: #333;
  font-size: 1.5rem !important;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
`;

const CardPosition = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  height: 41px;
  line-height: 1.25rem;
  margin: 0.375rem 0;
  text-align: center;
`;

const CardTopics = styled.p`
  margin-bottom: 2rem;
  text-align: center;
`;

const CardTopic = styled.span`
  font-size: 1.25rem;
  line-height: 2rem;
  margin-bottom: 0;
  margin-right: 0.375rem;
  &:after {
    content: ',';
  }
  &:last-child {
    &:after {
      content: '';
    }
  }
`;

const CardButton = styled.div`
  align-items: center;
  background-color: transparent;
  color: ${({ $theme }) => $theme.textColor};
  cursor: pointer;
  display: flex;
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  height: 3rem;
  justify-content: center;
  text-transform: uppercase;
  transition: 0.5s;
  width: 100%;

  ${breakpoints.lg} {
    justify-content: center;
  }
`;

const CardFooter = styled.div`
  /* margin-top: auto; */
`;

const StyledCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  transition-duration: 0.375s;
  width: 100%;
  &:hover ${CardImg} {
    box-shadow: 0px 0.375rem 0.75rem rgba(0, 0, 0, 0.25);
    border: 4px solid ${({ colors }) => colors.primary};
    /* filter: none; */
  }
  &:hover ${CardButton} {
    color: ${({ colors }) => colors.tertiary};
  }
  ${breakpoints.lg} {
    &:hover ${SVG} {
      opacity: 1 !important;
    }
    /* &:hover ${Circle1} {
      bottom: -2%;
    } */
    &:hover ${Blob1Styled} {
      top: -5%;
    }
    &:hover ${Blob2Styled} {
      bottom: 0;
    }
  }
  p,
  h3 {
    color: ${({ $theme }) => $theme.textColor};
  }
`;

const SpeakerSocials = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const SpeakerSocial = styled.a`
  margin-right: 0.625rem;
  svg {
    width: 1.5rem;
    circle {
      fill: #c4c4c4;
      transition: 0.5s;
    }
    path {
      fill: white;
      transition: 0.5s;
    }
  }
  :last-child {
    svg {
      path {
        fill: none;
        stroke: white;
        transition: 0.5s;
      }
    }
  }
  :hover {
    svg {
      cursor: pointer;

      circle {
        fill: ${({ colors }) => colors.primary};
      }
      path {
        fill: white;
      }
    }
    :last-child {
      svg {
        path {
          fill: none;
          stroke: white;
          transition: 0.5s;
        }
      }
    }
  }
`;

export default Speaker;
