import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button, BulletPoint } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { flexContainer, gridContainer, fullGridContainer, breakpoints } from 'styles';
import {
  FbIcon,
  InstaIcon,
  LinkedinIcon,
  TwitterIcon,
  LogoWhite,
  AgencyxLogo,
  CampaignxLogo,
  EventxLogo,
  AgencyxLogoContrast,
  GetBroadcastingLogo,
  MobIcon,
  LocoIcon
} from 'assets/svgs';

const EventFooter = () => {
  const { theme, contactRef } = useContext(LocalContext);
  const { user } = useContext(FirebaseContext);
  return (
    <>
      <FlexContainer>
        <Title>
          {user && (
            <BulletPoint
              top="-1rem"
              left="1.25rem"
              content={`
              <h4>Our Sponsors / Partners Section<br/> - More than Logos!</h4>
              <p>Event X Live events give sponsors and partners great opportunities to build brand engagement. As all attendees are online anyhow, it’s an easier consideration to engage digitally with sponsors and partners.</p>
              <p>Along with sponsor logos, we can play video and ads from their portfolio, they can sponsor show segments, we can showcase their latest products, branded imagery on all event X promotional material, digital media pack of assets to share re their event, at event virtual stage branding, downloadable content and links to their own websites as well as branding all post show content.</p>
              <p>We can encourage social media connections, run at event competitions, research and surveys and allow options for event participants to subscribe to sponsors databases directly. Customised analytic reporting can also be made available post event for sponsors to calculate their ROI.</p>
            `}
            />
          )}
          <H3>Our Partners</H3>
        </Title>
        <Partners $theme={theme}>
          <AgencyxLogo />
          <CampaignxLogo />
          <EventxLogo />
          <GetBroadcastingLogo />
        </Partners>
      </FlexContainer>
      <Donations $theme={theme}>
        {user && (
          <BulletPoint
            top="-1rem"
            bottom="1rem"
            right="0rem"
            content={`
            <p>For our charity and not for profit clients there is an option to Make a Donation running through all stages of the event, making it easy for the audience to contribute.</p>
            `}
          />
        )}

        <div>
          <H3 style={{ marginBottom: '0px', width: '100%' }}>Make A Donation</H3>
          <p>
            For Charity and Not-For-Profit events consider a `Make a Donation CTA` which can be
            simply linked to your existing online payment process.
          </p>
        </div>
        <Button width="12rem">Donate</Button>
      </Donations>
      <GridContainer $theme={theme}>
        <Subscribe $theme={theme}>
          <a href="https://www.epilepsyinenglish.ie/" target="blank">
            <LogoWhite width={250} />
          </a>
          <P style={{ marginBottom: '3rem' }}>Epilepsy in English Workshops virtual event is brought to you by Agency X in conjunction with Epilepsy in English and Future Neuro</P>
          <a href="https://agencyx.ie" target="blank">
            <AgencyxLogoContrast className="ax-logo" width={150} />
          </a>
        </Subscribe>
        <Contact id="contact" ref={contactRef}>
          <H3>Contact us</H3>
          <ContactDetail $theme={theme} href="tel:+353 1 6706122">
            <MobIcon />+ 353 (0)1 6706122
          </ContactDetail>
          <ContactDetail $theme={theme}>
            <LocoIcon />
            Media House, 24 South William Street
            <br /> Dublin 2, Ireland
          </ContactDetail>
        </Contact>
        <Socials>
          <H3>Follow us on Social</H3>
          <div>
            <SocialLink $theme={theme} href="https://www.facebook.com/AgencyXdublin" target="blank">
              <FbIcon />
            </SocialLink>
            <SocialLink $theme={theme} href="https://twitter.com/AgencyX_Dublin" target="blank">
              <TwitterIcon />
            </SocialLink>
            <SocialLink $theme={theme} href="https://www.linkedin.com/company/agencyx-dublin">
              <LinkedinIcon />
            </SocialLink>
            <SocialLink
              $theme={theme}
              href="https://www.instagram.com/agency_x_dublin/"
              target="blank">
              <InstaIcon />
            </SocialLink>
          </div>
        </Socials>
      </GridContainer>
      <FooterInfo>
        <FooterCopyright $theme={theme}>
          Copyright © 2020 - Panacea - Designed and Developed by Agency X{' '}
        </FooterCopyright>
        <FooterTerms $theme={theme}>
          {/* <a href="/">Terms & Conditions</a> */}
          <a href="/">Privacy Policy</a>
        </FooterTerms>
      </FooterInfo>
    </>
  );
};

const FlexContainer = styled.section`
  ${flexContainer}
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;
  margin-top: 6rem;
`;

const GridContainer = styled.footer`
  ${fullGridContainer}
  background: ${({ $theme }) => $theme.bg};
  color: ${({ $theme }) => $theme.contrastTextColor};
  grid-row-gap: 3.75rem;
  padding: 2.25rem 1.25rem;
  position: relative;

  ${breakpoints.sm} {
    padding: 40px;
  }
  ${breakpoints.lg} {
    padding: 2.25rem 0 5rem;
  }
`;

const H3 = styled.h3`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  margin-bottom: 2.5rem;
  margin-top: 0px;
`;

const P = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin-top: 0px;
`;

const Partners = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: center;
  margin: 2rem auto;
  padding: 0px 1.25rem;
  width: 100%;

  svg {
    height: 1.5rem;
    margin: 2rem;
    width: auto;
    path {
      fill: ${({ $theme }) => ($theme.className === 'contrast' ? 'yellow' : 'auto')};
    }
    :last-child {
      height: 5rem;
    }
  }
`;

const Donations = styled(FlexContainer)`
  align-items: center;
  background-color: ${({ $theme }) => $theme.bgLight};
  color: #000;
  display: grid;
  grid-gap: 0.625rem;
  grid-template-columns: repeat(6, 1fr);
  margin: 1.25rem;
  max-width: 100%;
  padding: 1.25rem 2.25rem;
  position: relative;
  text-align: center;

  div {
    grid-column: 1/7;
  }

  p {
    grid-column: 1/7;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }

  h3,
  p {
    color: ${({ $theme }) => $theme.textColor};
  }

  button {
    grid-column: 1/8;
    justify-self: center;
  }

  ${breakpoints.sm} {
    grid-column: 1/7;
    grid-gap: 1.25rem;
    text-align: left;
    margin: 2.5rem 1.25rem;
    div {
      grid-column: 1/5;
    }
    p {
      margin-bottom: 0px;
    }
    button {
      grid-column: 5/7;
      justify-self: end;
    }
  }

  ${breakpoints.lg} {
    grid-column: 2/12;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(10, 1fr);
    margin: 2.5rem auto;
    text-align: left;
    div {
      grid-column: 1/9;
    }
    p {
      grid-column: 1/9;
      margin-bottom: 0px;
    }
    button {
      grid-column: 9/11;
    }
  }
`;

const Subscribe = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  svg {
    margin-bottom: 2rem;
    max-width: 225px;
    path {
      fill: ${({ $theme }) => ($theme.className === 'contrast' ? 'yellow' : 'auto')};
    }
  }
  ${breakpoints.lg} {
    grid-column: 2/5;
  }
`;

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  ${breakpoints.lg} {
    grid-column: 6/9;
  }
`;

const ContactDetail = styled.a`
  align-items: center;
  color: ${({ $theme }) => $theme.contrastTextColor};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1.25rem;
  text-decoration: none;
  transition: 0.5s;
  :visited {
    color: ${({ $theme }) => $theme.contrastTextColor};
  }
  svg {
    margin-right: 0.75rem;
    width: 1.75rem;
    min-width: 1.75rem;
    height: 1.75rem;
    circle {
      transition: 0.5s;
      fill: ${({ $theme }) => ($theme.className === 'contrast' ? 'yellow' : $theme.fg)};
    }
    path {
      transition: 0.5s;
      stroke: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : 'white')};
    }
  }
  &:hover {
    color: ${({ $theme }) => $theme.fg};
    svg {
      circle {
        fill: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : 'white')};
      }
      path {
        stroke: ${({ $theme }) => ($theme.className === 'contrast' ? 'yellow' : $theme.fg)};
      }
    }
  }
`;

const Socials = styled.div`
  grid-column: 1/7;
  div {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  h3 {
    width: 100%;
  }
  ${breakpoints.lg} {
    grid-column: 9/12;
  }
`;

const SocialLink = styled.a`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1.25rem;
  transition: 0.5s;

  svg {
    margin-right: 1.5rem;
    width: 2.5rem;
    height: 2.5rem;
    circle {
      transition: 0.5s;
      fill: ${({ $theme }) => ($theme.className === 'contrast' ? 'yellow' : $theme.fg)};
    }
    path {
      transition: 0.5s;

      fill: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : 'white')};
    }
  }

  &:hover {
    color: ${({ $theme }) => $theme.fg};
    svg {
      circle {
        fill: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : 'white')};
      }
      path {
        fill: ${({ $theme }) => ($theme.className === 'contrast' ? 'white' : $theme.fg)};
      }
    }
  }
`;

const FooterInfo = styled.footer`
  ${gridContainer}
  align-items: start;
  padding: 1rem 1.25rem;
  ${breakpoints.sm} {
    grid-column: 1/7;
  }
  ${breakpoints.lg} {
    grid-column: 2/13;
    padding: 9px 0px;
  }
`;

const FooterCopyright = styled.p`
  color: ${({ $theme }) => $theme.textColor};
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  grid-column: 1/7;
  line-height: 1.25rem;
  margin-top: 0px;
  ${breakpoints.sm} {
    grid-column: 1/7;
  }
  ${breakpoints.lg} {
    grid-column: 1/8;
  }
`;

const FooterTerms = styled.div`
  display: flex;
  grid-column: 1/7;
  justify-content: flex-start;
  a {
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.25rem;
    margin-right: 1.25rem;
    color: ${({ $theme }) => $theme.textColor};
  }
  ${breakpoints.lg} {
    grid-column: 8/11;
  }
`;

const Title = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
`;

export default EventFooter;
