import * as React from 'react';

function SvgAgencyxLogoContrast(props) {
  return (
    <svg viewBox="0 0 174 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M33.715 0c3.154 0 5.443 1.282 7.187 3.295l-1.233.89c-1.338-1.62-3.523-2.689-5.954-2.689-4.491 0-7.962 3.437-7.962 8.477 0 4.987 3.47 8.513 7.962 8.513 2.555 0 4.563-1.282 5.62-2.35v-4.578h-7.91l.652-1.514h8.878v6.75A9.675 9.675 0 0133.715 20c-5.39 0-9.688-4.06-9.688-10.027C24.027 4.025 28.325 0 33.715 0zM74.23 2.956v16.67h-1.638V.32h1.656l11.978 16.438V.32h1.638v19.306h-1.603l-12.03-16.67zM97.218 9.991c0-5.93 4.263-9.991 9.618-9.991 3.1 0 5.39 1.354 7.011 3.42l-1.374.89c-1.198-1.674-3.294-2.814-5.637-2.814-4.439 0-7.91 3.437-7.91 8.477 0 4.987 3.471 8.478 7.91 8.478 2.343 0 4.439-1.122 5.637-2.814l1.374.872c-1.691 2.12-3.928 3.438-7.011 3.438-5.355.035-9.618-4.025-9.618-9.956zM127.745 11.38L120.153.32h1.972l6.448 9.493L135.02.32h1.973l-7.592 11.06v8.246h-1.656V11.38zM9.847.32H7.82L0 19.626h1.832L8.825 1.94l4.58 11.434H7.733l-.616 1.513h6.799l1.92 4.738h1.832L9.847.32zM63.115.32H50.362v1.514h12.753V.32zM59.839 8.655h-9.477v1.496h8.825l.652-1.496zM63.115 18.13H50.362v1.514h12.753V18.13z"
        fill="#fff"
      />
      <path d="M162.746 10.864l-6.852 8.798h2.061l5.795-7.605-1.004-1.193z" fill="#CE9200" />
      <path
        d="M165.653 9.706l7.258-9.385h-2.061l-6.218 8.192-6.183-8.192h-2.096l14.955 19.305h2.026l-7.681-9.92z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgAgencyxLogoContrast;
