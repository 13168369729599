import firebase from 'firebase/app';
import firebaseConfig from './config';

let firebaseInstance;

class Firebase {
  constructor(app) {
    if (!firebaseInstance) {
      app.initializeApp(firebaseConfig);
      this.auth = app.auth();
      /* Realtime Databse */
      this.rtdb = app.database();
      /* Firestore Databse */
      this.fsdb = app.firestore();
      this.storage = app.storage;
      // Need to do the below to solve region issue. Not ideal - and there seems to be an error in the Firebase docs - but it works!
      // this.functions = app.functions('europe-west2');
      this.functions = firebase.app().functions('europe-west2');
      // if (window.location.hostname.includes('localhost')) {
      //   this.auth.useEmulator('http://localhost:9099');
      //   this.fsdb.useEmulator('localhost', 8080);
      //   this.functions.useEmulator('localhost', 5001);
      // }
    }
  }

  async createUserInAuth({ email, password }) {
    const { user } = await this.auth.createUserWithEmailAndPassword(email.toLowerCase(), password);
    return user;
  }

  async createUserInDatabase(newUserLocalStorageData) {
    const createUserInDatabaseCallable = this.functions.httpsCallable('createUserInDatabase');
    return createUserInDatabaseCallable(newUserLocalStorageData);
  }

  async login(email, password) {
    return this.auth.signInWithEmailAndPassword(email.toLowerCase(), password);
  }

  async logout() {
    return this.auth.signOut();
  }

  async fetchAllUsers() {
    const fetchAllUsersCallable = this.functions.httpsCallable('fetchAllUsers');
    return fetchAllUsersCallable();
  }

  async getUserFromDatabaseWithUID({ uid }) {
    return this.fsdb.collection('users').where('uid', '==', uid).limit(1).get();
  }

  async checkIfUserAlreadyExists({ email }) {
    return this.fsdb.collection('userEmails').where('email', '==', email).limit(1).get();
  }

  async sendEmoji({ eventName, emojiType }) {
    return this.rtdb
      .ref(`emojis/${eventName}`)
      .push()
      .set({ emojiType, timestamp: firebase.database.ServerValue.TIMESTAMP });
  }

  async saveNewPoll({ eventName, poll }) {
    return this.rtdb.ref(`polls/${eventName}`).push().set(poll);
  }

  async editPoll({ eventName, poll, pid }) {
    return this.rtdb.ref(`polls/${eventName}/${pid}`).set(poll);
  }

  async deletePoll({ eventName, pid }) {
    return this.rtdb.ref(`polls/${eventName}/${pid}`).remove();
  }

  async generateCSVReport() {
    const generateCSVReportCallable = this.functions.httpsCallable('generateCSVReport');
    return generateCSVReportCallable();
  }

  async generateBreakoutRoomsReport() {
    const generateBreakoutRoomsReportCallable = this.functions.httpsCallable(
      'generateBreakoutRoomsReport'
    );
    return generateBreakoutRoomsReportCallable();
  }

  async generateLoggedInOnTheDayReport({ users }) {
    const generateLoggedInOnTheDayReportCallable = this.functions.httpsCallable(
      'generateLoggedInOnTheDayReport'
    );
    return generateLoggedInOnTheDayReportCallable({ users });
  }

  async generateEventCommentsReport({ selectedEvent }) {
    const generateEventCommentsReportCallable = this.functions.httpsCallable(
      'generateEventCommentsReport'
    );
    return generateEventCommentsReportCallable({ selectedEvent });
  }

  async sendSignInWithMagicLinkEmail({ firstName, email, actionCodeSettings }) {
    const sendSignInWithEmailLinkEmailCallable = this.functions.httpsCallable(
      'sendSignInWithMagicLinkEmail'
    );
    return sendSignInWithEmailLinkEmailCallable({
      firstName,
      email,
      actionCodeSettings
    });
  }

  async updateNameInDatabase({ uid, name }) {
    return this.fsdb.collection('users').doc(uid).update({
      name
    });
  }

  async updateEmailInDatabase({ uid, email }) {
    const { fsdb } = this;

    const usersRef = fsdb.collection('users').doc(uid);
    const userEmailsRef = fsdb.collection('userEmails').doc(uid);

    const batch = fsdb.batch();

    batch.update(usersRef, {
      email: email.toLowerCase()
    });

    batch.update(userEmailsRef, {
      email: email.toLowerCase()
    });

    return batch.commit();
  }

  async applyActionCode(actionCode) {
    return this.auth.applyActionCode(actionCode);
  }

  async checkActionCode(actionCode) {
    return this.auth.checkActionCode(actionCode);
  }

  async uploadAvatarToDatabase(avatarFile) {
    const uploadAvatarToDatabaseCallable = this.functions.httpsCallable('uploadAvatarToDatabase');
    return uploadAvatarToDatabaseCallable({ avatarFile });
  }

  async sendReminderEmail() {
    const sendReminderEmailCallable = this.functions.httpsCallable('sendReminderEmail');
    return sendReminderEmailCallable();
  }

  async fetchUser({ uid }) {
    return this.fsdb.collection('users').doc(uid).get();
  }

  async fetchEventParticipants({ lastFetchedParticipantDoc }) {
    let query;

    if (lastFetchedParticipantDoc) {
      query = this.fsdb
        .collection('users')
        .where('workshops.geneTherapy', '==', true)
        .orderBy('name')
        .startAfter(lastFetchedParticipantDoc)
        .limit(20);
    } else {
      query = this.fsdb
        .collection('users')
        .where('workshops.geneTherapy', '==', true)
        .orderBy('name')
        .limit(20);
    }

    return query.get();
  }

  async postEventComment({ eventName, avatarUrl, name, text, timestamp, uid }) {
    return this.fsdb
      .collection('events')
      .doc(eventName)
      .collection('comments')
      .add({
        avatarUrl,
        name,
        text,
        timestamp,
        uid,
        pinned: {
          status: false,
          timestamp: 0
        }
      });
  }

  async deleteEventComment({ eventName, cid }) {
    return this.fsdb.collection('events').doc(eventName).collection('comments').doc(cid).delete();
  }

  async pinEventComment({ eventName, cid }) {
    return this.fsdb
      .collection('events')
      .doc(eventName)
      .collection('comments')
      .doc(cid)
      .update({
        pinned: {
          status: true,
          timestamp: firebase.firestore.FieldValue.serverTimestamp()
        }
      });
  }

  async unpinEventComment({ eventName, cid }) {
    return this.fsdb
      .collection('events')
      .doc(eventName)
      .collection('comments')
      .doc(cid)
      .update({
        pinned: {
          status: false,
          timestamp: 0
        }
      });
  }

  async starQuestion({ uid, qid }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .collection('questions')
      .doc(qid)
      .update({
        starred: {
          status: true,
          timestamp: firebase.firestore.FieldValue.serverTimestamp()
        }
      });
  }

  async unstarQuestion({ uid, qid }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .collection('questions')
      .doc(qid)
      .update({
        starred: {
          status: false,
          timestamp: 0
        }
      });
  }

  async getLivestreamCommentsCSVReport({ email }) {
    const getLivestreamCommentsCSVReportCallable = this.functions.httpsCallable(
      'getLivestreamCommentsCSVReport'
    );
    return getLivestreamCommentsCSVReportCallable({ email: email.toLowerCase() });
  }

  async grantRegistrantAccessToTheseEvents({ uid, eventNames }) {
    const usersRef = this.fsdb.collection('users').doc(uid);

    return usersRef.update({
      eventsUserWantsToAccess: firebase.firestore.FieldValue.arrayRemove(...eventNames),
      eventsUserCanAccess: firebase.firestore.FieldValue.arrayUnion(...eventNames),
      eventsUserHasBeenDeniedAccessTo: firebase.firestore.FieldValue.arrayRemove(...eventNames),
      pendingSiteRegistrant: false
    });
  }

  async denyRegistrantAccessToTheseEvents({ uid, eventNames }) {
    const usersRef = this.fsdb.collection('users').doc(uid);

    return usersRef.update({
      eventsUserHasBeenDeniedAccessTo: firebase.firestore.FieldValue.arrayUnion(...eventNames),
      eventsUserCanAccess: firebase.firestore.FieldValue.arrayRemove(...eventNames),
      eventsUserWantsToAccess: firebase.firestore.FieldValue.arrayRemove(...eventNames)
    });
  }

  async denyRegistrantAccessToSite({ uid }) {
    const usersRef = this.fsdb.collection('users').doc(uid);

    return usersRef.update({
      pendingSiteRegistrant: false
    });
  }

  async sendSiteAccessGrantedEmail({ registrantName, registrantEmail }) {
    const sendSiteAccessGrantedEmailCallable = this.functions.httpsCallable(
      'sendSiteAccessGrantedEmail'
    );
    return sendSiteAccessGrantedEmailCallable({
      registrantName,
      registrantEmail
    });
  }

  async sendEventAccessGrantedEmail({ attendeeName, attendeeEmail, event }) {
    const sendEventAccessGrantedEmailCallable = this.functions.httpsCallable(
      'sendEventAccessGrantedEmail'
    );
    return sendEventAccessGrantedEmailCallable({
      attendeeName,
      attendeeEmail: attendeeEmail.toLowerCase(),
      event
    });
  }

  async registerForEventIfUserAlreadyHasAnAccount({ eventName, uid }) {
    const registerForEventIfUserAlreadyHasAnAccountCallable = this.functions.httpsCallable(
      'registerForEventIfUserAlreadyHasAnAccount'
    );
    return registerForEventIfUserAlreadyHasAnAccountCallable({
      eventName,
      uid
    });
  }

  async sendEventWelcomeEmail({ attendeeName, attendeeEmail, event }) {
    const sendEventWelcomeEmailCallable = this.functions.httpsCallable('sendEventWelcomeEmail');
    return sendEventWelcomeEmailCallable({
      attendeeName,
      attendeeEmail,
      event
    });
  }

  async postProjectComment({
    avatarUrl,
    eventName,
    name,
    profession,
    projectDocument,
    text,
    timestamp,
    uid,
    workplaceName
  }) {
    return this.fsdb
      .collection('events')
      .doc(eventName)
      .collection('projects')
      .doc(projectDocument)
      .collection('comments')
      .doc(timestamp.toString())
      .set({
        avatarUrl,
        name,
        text,
        timestamp,
        profession,
        workplaceName,
        uid,
        pinned: {
          status: false,
          timestamp: 0
        }
      });
  }

  async pinProjectComment({ eventName, projectDocument, cid }) {
    return this.fsdb
      .collection(eventName)
      .collection('projects')
      .doc(projectDocument)
      .collection('comments')
      .doc(cid)
      .update({
        pinned: {
          status: true,
          timestamp: firebase.firestore.FieldValue.serverTimestamp()
        }
      });
  }

  async unpinProjectComment({ eventName, cid, projectDocument }) {
    return this.fsdb
      .collection(eventName)
      .collection('projects')
      .doc(projectDocument)
      .collection('comments')
      .doc(cid)
      .update({
        pinned: {
          status: false,
          timestamp: 0
        }
      });
  }

  async deleteProjectComment({ eventName, cid, projectDocument }) {
    return this.fsdb
      .collection(eventName)
      .collection('projects')
      .doc(projectDocument)
      .collection('comments')
      .doc(cid)
      .delete();
  }

  async submitNewQuestion({ uid, eventName, text, name }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .collection('questions')
      .add(
        name
          ? {
              eventName,
              text,
              timestamp: Date.now(),
              uid,
              name,
              starred: {
                status: false,
                timestamp: 0
              }
            }
          : {
              eventName,
              text,
              timestamp: Date.now(),
              uid,
              starred: {
                status: false,
                timestamp: 0
              }
            }
      );
  }

  async answerThisQuestionLive({ eventName, text }) {
    return this.fsdb.collection('events').doc(eventName).update({
      questionCurrentlyBeingAnsweredLive: text
    });
  }

  async stopShowingAnswerLiveOverlay({ eventName }) {
    return this.fsdb.collection('events').doc(eventName).update({
      questionCurrentlyBeingAnsweredLive: null
    });
  }

  async submitAnswer({ text, qid, uid }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .collection('questions')
      .doc(qid)
      .update({
        answer: {
          text,
          timestamp: Date.now()
        }
      });
  }

  async uploadProject({ eventName, title, author, summary, poster, timestamp }) {
    const uploadProjectCallable = this.functions.httpsCallable('uploadProject');
    return uploadProjectCallable({
      author,
      eventName,
      poster,
      summary,
      timestamp,
      title
    });
  }

  async convertPDFtoPNG({ fileName }) {
    const convertPDFtoPNGCallable = this.functions.httpsCallable('convertPDFtoPNG');
    return convertPDFtoPNGCallable({
      fileName
    });
  }

  /* TODO: We seem to have lost the code that calls this function? */
  async updateVideoSessionData({ uid, timeRanges }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .update({
        videoSessionData: firebase.firestore.FieldValue.arrayUnion(...timeRanges)
      });
  }

  async grantUserAdminPermissions({ uid }) {
    const grantUserAdminPermissionsCallable = this.functions.httpsCallable(
      'grantUserAdminPermissions'
    );
    return grantUserAdminPermissionsCallable({ uid });
  }

  async grantUserModeratorPermissions({ uid }) {
    const grantUserModeratorPermissionsCallable = this.functions.httpsCallable(
      'grantUserModeratorPermissions'
    );
    return grantUserModeratorPermissionsCallable({ uid });
  }

  async showSelectedPoll({ selectedEvent, selectedPollData, currentlyShownPollPid }) {
    if (currentlyShownPollPid) {
      const pollsRef = this.rtdb.ref(`polls/${selectedEvent.name}`);
      return pollsRef.update({
        [`${currentlyShownPollPid}/showPoll`]: false,
        [`${selectedPollData.pid}/showPoll`]: true
      });
    }
    return this.rtdb
      .ref(`polls/${selectedEvent.name}/${selectedPollData.pid}`)
      .update({ showPoll: true });
  }

  async endSelectedPoll({ selectedEvent, selectedPollData }) {
    return this.rtdb
      .ref(`polls/${selectedEvent.name}/${selectedPollData.pid}`)
      .update({ showPoll: false, isActive: false });
  }

  async showResultsOfSelectedPoll({ eventName, pid }) {
    return this.rtdb.ref(`polls/${eventName}/${pid}`).update({ showResults: true });
  }

  subscribeToUserUpdates({ uid, onSnapshot }) {
    return this.fsdb.collection('users').doc(uid).onSnapshot(onSnapshot);
  }

  subscribeToEventUpdates({ eventName, onSnapshot }) {
    return this.fsdb.collection('events').doc(eventName).onSnapshot(onSnapshot);
  }

  subscribeToEventComments({ eventName, onSnapshot }) {
    return this.fsdb
      .collection('events')
      .doc(eventName)
      .collection('comments')
      .orderBy('pinned.status', 'desc')
      .orderBy('pinned.timestamp', 'asc')
      .orderBy('timestamp', 'desc')
      .onSnapshot(onSnapshot);
  }

  subscribeToProjectComments({ eventName, projectDocument, onSnapshot }) {
    return this.fsdb
      .collection('events')
      .doc(eventName)
      .collection('projects')
      .doc(projectDocument)
      .collection('comments')
      .orderBy('pinned.status', 'desc')
      .orderBy('pinned.timestamp', 'asc')
      .orderBy('timestamp', 'desc')
      .onSnapshot(onSnapshot);
  }

  subscribeToEmojis({ eventName, onSnapshot }) {
    const emojisRef = this.rtdb.ref(`emojis/${eventName}`);

    emojisRef.orderByChild('timestamp').startAfter(Date.now()).on('child_added', onSnapshot);

    return emojisRef;
  }

  subscribeModeratorToAllSubmittedQuestions({ onSnapshot, eventName }) {
    return this.fsdb
      .collectionGroup('questions')
      .where('eventName', '==', eventName)
      .orderBy('starred.status', 'desc')
      .orderBy('starred.timestamp', 'asc')
      .orderBy('timestamp', 'desc')
      .onSnapshot(onSnapshot);
  }

  subscribeUserToTheirSubmittedQuestions({ onSnapshot, uid, eventName }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .collection('questions')
      .where('eventName', '==', eventName)
      .where('uid', '==', uid)
      .onSnapshot(onSnapshot);
  }

  subscribeToPolls({ eventName, onSnapshot }) {
    const pollsRef = this.rtdb.ref(`polls/${eventName}`);

    pollsRef.on('value', onSnapshot);

    return pollsRef;
  }
}

function getFirebaseInstance(app) {
  if (!firebaseInstance && app) {
    firebaseInstance = new Firebase(app);
    return firebaseInstance;
  }

  if (firebaseInstance) {
    return firebaseInstance;
  }

  return null;
}

export default getFirebaseInstance;
