import React, { useContext } from 'react';
import styled from 'styled-components';
import { DateButton, BulletPoint, Livestream, EventHeaderBackground } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { Logo, LogoContrast } from 'assets/svgs';
import { H1, H5, breakpoints } from 'styles';

const EventHeader = (props) => {
  const { theme } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const currentUser = firebase?.auth?.currentUser;

  return (
    <Container
      $theme={theme}
      style={{ backgroundColor: '#EFEDF5' }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}>
      {/* <EventHeaderBackground colors={props.colors} /> */}
      {currentUser && <Livestream {...props} />}
    </Container>
  );
};

const LogoContainer = styled.div`
  height: auto;
  margin-bottom: 3rem;
  max-width: 80%;
  width: 750px;
  ${breakpoints.sm} {
    margin: 4rem 0;
    max-width: 100%;
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  position: relative;
  width: 100%;
  z-index: 3;
  p {
    font-weight: normal;
    color: ${({ $theme }) => $theme.textMuted};
  }
  ${breakpoints.sm} {
    grid-column: 1/7;
    padding: 40px;
  }
  ${breakpoints.lg} {
    grid-column: 2/9;
  }
  ${breakpoints.xl} {
    grid-column: 2/8;
  }
`;

const SlideDate = styled(H5)`
  :first-child {
    color: ${({ $theme }) => $theme.fg};
  }
`;

const SlideTitle = styled(H1)`
  color: ${({ $theme }) => $theme.textColor};
`;

const SlideCaptionTitle = styled.h2`
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.5rem;
  margin-bottom: 1.25rem;
  ${breakpoints.lg} {
    font-size: 4rem;
    line-height: 1.25em;
    margin-bottom: 1.5rem;
  }
  ${breakpoints.xl} {
    margin-bottom: 3rem;
  }
`;

const SlideCaptionSubtitle = styled.h3`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5em;
  margin-bottom: 1.25rem;
  position: relative;
  ${breakpoints.lg} {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
`;

const SlideCaptionText = styled.p`
  color: #007fc0;
  font-size: 1.25rem;
  line-height: 1.5em;
  margin-bottom: 1.5em;
`;

const HeaderButtons = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5rem;
`;

// const DesktopOnly = styled.div`
//   display: none;
//   ${ breakpoints.lg } {
//     display: block;
//     margin-right: 2.5rem;
//   }
// `;

// const SVGBackground = styled.div`
//   position: absolute;
//   top: -60px;
//   right: 0;
//   height: calc(100% + 60px);
//   width: 100%;
//   overflow: hidden;
//   svg {
//     :nth-child(1) {
//       position: absolute;
//       height: 100%;
//       width: auto;
//       right: -75%;
//       z-index: 1;
//       ${breakpoints.sm} {
//         right: 0;
//       }
//     }
//     :nth-child(2) {
//       position: absolute;
//       top: 50%;
//       right: 50px;
//       transform: translateY(-50%);
//       height: 400px;
//       width: 400px;
//       z-index: 2;
//     }
//     :nth-child(3) {
//       position: absolute;
//       top: 30%;
//       right: 450px;
//       transform: translateY(-50%);
//       height: auto;
//       width: 120px;
//       z-index: 2;
//     }
//     :nth-child(4) {
//       position: absolute;
//       top: 25%;
//       right: 75px;
//       transform: translateY(-50%);
//       height: auto;
//       width: 160px;
//       z-index: 2;
//     }
//     :nth-child(5) {
//       position: absolute;
//       bottom: 25%;
//       right: 420px;
//       transform: translateY(50%);
//       height: auto;
//       width: 120px;
//       z-index: 3;
//     }
//     :nth-child(6) {
//       position: absolute;
//       bottom: 20%;
//       right: 50px;
//       transform: translateY(50%);
//       height: auto;
//       width: 200px;
//       z-index: 3;
//     }
//     :nth-child(7) {
//       position: absolute;
//       bottom: 15%;
//       right: 350px;
//       transform: translateY(50%);
//       height: auto;
//       width: 60px;
//       z-index: 2;
//     }
//     :nth-child(8) {
//       position: absolute;
//       bottom: 35%;
//       z-index: 1;
//       right: 450px;
//       transform: translateY(50%);
//       height: auto;
//       width: 250px;
//       z-index: 2;
//     }
//   }
// `;

const Container = styled.header`
  align-items: center;
  background: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : 'white')};
  color: ${({ $theme }) => $theme.textColor};
  display: grid;
  grid-column: 1/7;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
  padding: 6rem 0.625rem 0;
  position: relative;
  width: 100vw;
  @media (min-width: 768px) and (min-height: 1000px) and (max-width: 1150px) {
    min-height: 70vh;
    padding: 5rem 0;
  }
  ${breakpoints.lg} {
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export default EventHeader;
