import React, { useContext } from 'react';
import styled from 'styled-components';
import { RegistrationForm, Button, Modal, BulletPoint, HubHeaderBackground } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import { Logo, LogoContrast } from 'assets/svgs';
import { H2, H3, P, breakpoints } from 'styles';

const HubHeader = (props) => {
  const { mobile, theme } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const currentUser = firebase?.auth?.currentUser;

  const slide = {
    date: 'November',
    title: 'Virtual Workshops'
    // captionTitle: 'Welcome to the Panacea Expo'
  };

  const data = useStaticQuery(graphql`
    query {
      brain: file(relativePath: { eq: "assets/images/brain.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 842
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  return (
    <Container
      $theme={theme}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}>
      <HubHeaderBackground />
      <TextBox $theme={theme}>
        <SlideDate $theme={theme}>{slide.date}</SlideDate>
        <SlideTitle $theme={theme}>{slide.title}</SlideTitle>
        {/* <LogoContainer>
          {theme.className === 'contrast' ? <LogoContrast /> : <Logo />}
        </LogoContainer> */}
        <>
          <SlideCaptionSubtitle>
            {/* {currentUser && (
              <BulletPoint
                top="-2rem"
                left="0"
                content={`
                    <p>
                    Here we create a custom designed welcome page for your online event. With key imagery and call to action (CTA) buttons such as Register here, Save the Date and essential information.
                    </p>
                  `}
              />
            )} */}
            Epilepsy
            <br />
            in English
            <br />
            Workshops
          </SlideCaptionSubtitle>
          <SlideCaptionText $theme={theme}>
            The Epilepsy in English workshops bring cutting-edge
            <br />
            epilepsy research directly to you, all in plain English.
          </SlideCaptionText>
        </>
        {/* {!currentUser && (
          <HeaderButtons>
            {mobile ? (
              <Modal
                trigger={<Button width="11rem">Register Here</Button>}
                modalContent={<RegistrationForm colors={props.colors} />}
              />
            ) : !mobile ? (
              <Button width="11rem" onClick={props.scrollToRegister}>
                Register Here
              </Button>
            ) : null}
          </HeaderButtons>
        )} */}
      </TextBox>
      <Brain image={getImage(data.brain)} alt="Brain" imgStyle={{ objectFit: 'contain' }} />
    </Container>
  );
};

const Container = styled.header`
  align-items: center;
  background: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : 'white')};
  color: ${({ $theme }) => $theme.textColor};
  display: grid;
  grid-column: 1/7;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  justify-content: center;
  margin-bottom: 1rem;
  min-height: 100vh;
  overflow: visible;
  padding: 0.625rem;
  padding-top: 5rem;
  position: relative;
  width: 100vw;
  /* overflow-x: hidden; */

  /* .gatsby-image-wrapper {
    display: none;
  } */

  ${breakpoints.sm} and (min-height: 1000px) and (max-width: 1150px) {
    min-height: 70vh;
    padding: 4rem 0;
  }

  ${breakpoints.lg} {
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
    height: auto;
    margin-bottom: 3rem;
    /* .gatsby-image-wrapper {
      bottom: 0;
      display: block;
      height: 90%;
      min-width: 600px;
      object-fit: contain !important;
      position: absolute !important;
      right: 0;
    } */
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  margin-top: 13.125rem;
  position: relative;
  width: 100%;
  z-index: 3;
  p {
    color: ${({ $theme }) => $theme.textMuted};
    font-weight: normal;
  }
  ${breakpoints.sm} {
    grid-column: 2/6;
    margin-top: unset !important;
    padding: 4rem 0;
  }
  ${breakpoints.lg} {
    grid-column: 2/8;
  }
  @media screen and (min-width: 1700px) {
    grid-column: 3/9;
  }
`;

const Brain = styled(GatsbyImage)`
  position: absolute !important;
  right: -7.5rem;
  top: 0;
  width: 451px;

  @media screen and (min-width: 550px) {
    width: 600px;
  }

  @media screen and (min-width: 1000px) {
    width: 720px;
  }

  @media screen and (min-width: 1130px) {
    height: 90%;
    min-width: 842px;
    width: 80%;
    right: -12%;
    top: 7.15%;
  }

  @media screen and (min-width: 1600px) {
    width: 100%;
    right: unset;
    grid-column: 5/13;
  }

  @media screen and (min-width: 2800px) {
    width: 100%;
    right: unset;
    grid-column: 5/12;
  }
`;

const LogoContainer = styled.div`
  height: auto;
  margin-bottom: 3rem;
  max-width: 80%;
  width: 750px;
  ${breakpoints.sm} {
    margin: 4rem 0;
    max-width: 100%;
  }
`;

const SlideDate = styled(H2)`
  :first-child {
    color: ${({ $theme }) => $theme.fg};
  }
  margin-bottom: 0;
  ${breakpoints.lg} {
    margin-bottom: 0;
  }
`;

const SlideTitle = styled(SlideDate)`
  margin-bottom: 0.375rem;
`;

const SlideCaptionSubtitle = styled(H3)`
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 750;
  line-height: 1.5em;
  margin-bottom: 1.25rem;
  position: relative;
  ${breakpoints.lg} {
    font-size: 5.25rem;
    line-height: 1.31em;
    margin-bottom: 1.5rem;
  }
`;

const SlideCaptionText = styled(P)`
  color: #007fc0;
  font-size: 1rem;
  line-height: 1.5em;
  margin-bottom: 1.5em;
  ${breakpoints.lg} {
    font-size: 1.25rem;
  }
`;

const HeaderButtons = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5rem;
`;



export default HubHeader;
