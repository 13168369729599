import React, { useEffect, useState, useContext } from 'react';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { LogoSmall, LogoSmallContrast, User } from 'assets/svgs';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import {
  Modal,
  AccessibilityBar,
  RegistrationForm,
  LoginModal,
  UserProfile,
  BulletPoint,
  EventList
} from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { useLocation } from '@reach/router';
import { fullGridContainer, breakpoints } from 'styles';
import { motion, AnimatePresence } from 'framer-motion';
import { useWindowSize, useEventData } from 'hooks';
import { easeInOut } from '@popmotion/easing';
import { AnimatedEyeIcon } from 'assets/animated-svgs';

const Navbar = ({ colors }) => {
  const { edges: events } = useEventData();
  const { theme, mobile, scrollTo, galleryRef, contactRef, faqRef } = useContext(LocalContext);
  const { windowWidth, windowHeight } = useWindowSize();
  const { pathname } = useLocation();
  const { user, firebase, loading } = useContext(FirebaseContext);
  const [navOpen, setNavOpen] = useState(false);
  const [themeToggleOpen, setThemeToggleOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState(pathname);
  let isMounted = true;
  const location = useLocation();

  useEffect(
    () => () => {
      isMounted = false;
    },
    []
  );

  useEffect(() => setActiveLink(pathname), [pathname]);

  const handleScroll = () => {
    const scrollNavTop = window.scrollY;
    if (scrollNavTop >= 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleNav = () => {
    setThemeToggleOpen(false);
    setNavOpen(!navOpen);
  };

  const toggleTheme = () => {
    setNavOpen(false);
    setThemeToggleOpen(!themeToggleOpen);
  };

  const toggleActiveLink = (link) => {
    toggleNav();
    setActiveLink(link);
  };

  const handleLogout = () =>
    firebase
      .logout()
      .then(() => {
        document.querySelector('html').classList.remove('scroll-lock');
        navigate('/');
      })
      .catch((error) => isMounted && console.error(error.message));

  return (
    <>
      <StyledNavbar
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        scrolled={isScrolled}
        themeToggle={themeToggleOpen}
        $theme={theme}>
        <Background $theme={theme} />
        <LogoContainer
          to="/"
          aria-label="Home"
          onClick={() => {
            toggleActiveLink('welcome');
          }}>
          <StaticImage src="../../../assets/images/logos/eie-logo.png" width="304" alt="EIE logo" />
          {
            windowWidth > 700 && (
              <>
                <StaticImage src="../../../assets/images/logos/future-neuro-logo.png" width="96" alt="Future Neuro Logo" />
                <StaticImage src="../../../assets/images/logos/rcsi-logo.png" width="66" alt="RCSI logo" />
                <StaticImage src="../../../assets/images/logos/epilepsy-ireland-logo.png" width="61" alt="Epilepsy Ireland logo" />
                <StaticImage src="../../../assets/images/logos/dcu-logo.png" width="35" alt="DCU logo" />
                <StaticImage src="../../../assets/images/logos/sfi-logo.png" width="76" alt="SFI logo" />
              </>
            )
          }
        </LogoContainer>

        <AnimatePresence>
          {(navOpen || !mobile) && (
            <NavLinks
              $theme={theme}
              windowHeight={windowHeight}
              initial={mobile ? { x: '100vw' } : { x: 0 }}
              animate={{ x: 0 }}
              exit={{ x: '100vw' }}
              transition={{ duration: 0.5, ease: easeInOut }}>
              <NavLink
                  to="/"
                  $theme={theme}
                  className={activeLink === '/' ? 'active' : null}
                  style={{ marginRight: windowWidth >= 1150 ? '1.75rem' : 0 }}
                  onClick={() => {
                    toggleActiveLink('/');
                  }}>
                  Home
              </NavLink>
              {/* {events.length > 1 && user && <EventList toggleNav={toggleNav} />} */}
              {events.length === 1 && user && (
                <NavLink
                  $theme={theme}
                  to="/livestream"
                  className={activeLink === '/livestream' ? 'active' : null}
                  onClick={() => {
                    toggleActiveLink('/livestream/');
                  }}>
                  Livestream
                </NavLink>
              )}
              {mobile && !loading && firebase?.auth?.currentUser && user && (
                <LoginButton $theme={theme}>
                  <Modal
                    trigger={
                      <>
                        <p style={{ marginRight: '.625rem', fontWeight: '700' }}>
                          {user?.name?.split(' ')[0] || user.email}
                        </p>
                        {user.avatarUrl ? (
                          <ProfileImage src={user.avatarUrl} alt={user?.name} $theme={theme} />
                        ) : (
                          <User />
                        )}
                      </>
                    }
                    modalContent={<UserProfile handleLogout={handleLogout} />}
                  />
                </LoginButton>
              )}
              {mobile && !loading && !firebase?.auth?.currentUser && !user && (
                <>
                  <RegisterButton $theme={theme}>
                    <Modal
                      trigger={<p>Register</p>}
                      modalContent={<RegistrationForm colors={colors} />}
                    />
                  </RegisterButton>

                  <LoginButton $theme={theme}>
                    <Modal
                      trigger={
                        <>
                          <p>Login </p>
                          <User />
                        </>
                      }
                      modalContent={<LoginModal colors={colors} />}
                    />
                  </LoginButton>
                </>
              )}
            </NavLinks>
          )}
        </AnimatePresence>
        {!mobile && !loading && firebase?.auth?.currentUser && user && (
          <UserIcon $theme={theme}>
            <Modal
              trigger={
                <>
                  <p style={{ marginRight: '.625rem', fontWeight: '700' }}>
                    {user?.name?.split(' ')[0] || user?.email}
                  </p>
                  {user.avatarUrl ? (
                    <ProfileImage src={user.avatarUrl} alt={user?.name} $theme={theme} />
                  ) : (
                    <User />
                  )}
                </>
              }
              modalContent={<UserProfile handleLogout={handleLogout} />}
            />
          </UserIcon>
        )}
        {!mobile && !loading && !firebase?.auth?.currentUser && !user && (
          <UserIcon $theme={theme}>
            <Modal
              trigger={
                <>
                  {' '}
                  <p style={{ marginRight: '.625rem', fontWeight: '700' }}>Login</p>
                  <User />
                </>
              }
              modalContent={<LoginModal colors={colors} />}
            />
          </UserIcon>
        )}
        {/* <ThemeToggle onClick={() => toggleTheme()} open={themeToggleOpen} $theme={theme}>
          {user && (
            <BulletPoint
              top=".5rem"
              right="1rem"
              width="11rem"
              content={`
                      <p>Accessibility Bar</p>`}
            />
          )}
          <AnimatedEyeIcon />
        </ThemeToggle> */}
        <AnimatePresence>{themeToggleOpen && <AccessibilityBar />}</AnimatePresence>
        <NavToggler
          onClick={() => {
            toggleNav();
          }}
          $theme={theme}
          aria-label="Menu">
          <NavBurger $theme={theme} navOpen={navOpen}>
            <BurgerBar $theme={theme} navOpen={navOpen} />
            <BurgerBar $theme={theme} navOpen={navOpen} />
            <BurgerBar $theme={theme} navOpen={navOpen} />
          </NavBurger>
        </NavToggler>
      </StyledNavbar>
    </>
  );
};

const ProfileImage = styled.img`
  background: #c4c4c4;
  border: 0.125rem solid ${({ $theme }) => $theme.fg};
  border-radius: 50%;
  height: 1.625rem;
  object-fit: cover;
  width: 1.625rem;
`;

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  grid-area: logo;
  grid-column: 1/6;
  grid-column-gap: 20px;
  justify-content: space-between;

  @media screen and (min-width: 700px) {
    grid-column: 1/5;
  }

  @media screen and (min-width: 900px) {
    grid-column: 1/4;
  }

  ${breakpoints.lg} {
    grid-column: 2/8;
  }

  @media screen and (min-width: 1683px) {
    grid-column: 3/9;
  }
`;

const Logo = styled(GatsbyImage)`
  margin-left: 0.25rem;
  max-width: 12.375rem;
  width: 100%;

  ${breakpoints.lg} {
    max-width: 25.875rem;
  }
`;

const RegisterButton = styled.div`
  background: ${({ $theme }) => $theme.fg};
  border: none;
  color: ${({ $theme }) => ($theme.className === 'contrast' ? '#000' : 'white')};
  cursor: pointer;
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 3rem;
  outline: none;
  padding-top: 0.125rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`;

const LoginButton = styled.button`
  background: ${({ $theme }) => $theme.bgLight};
  border: none;
  cursor: pointer;
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 3rem;
  max-width: 100%;
  outline: none;
  padding-top: 0.125rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  div {
    align-items: center;
    color: ${({ $theme }) => $theme.textColor};
    display: flex;
    justify-content: center;
  }

  svg {
    max-height: 1.25rem;
    margin-left: 0.5em;
    path {
      fill: ${({ $theme }) => $theme.textColor};
    }

    circle {
      stroke: ${({ $theme }) => $theme.textColor};
    }
  }

  ${breakpoints.lg} {
    background: transparent;
    justify-content: flex-end;
  }
`;

const UserIcon = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  grid-area: user;
  grid-column: 10/12;
  height: 100%;
  outline: 0;
  width: 100%;

  @media screen and (min-width: 1683px) {
    grid-column: 8/11;
    position: relative;
    right: 1.8rem;
  }

  div {
    align-items: center;
    color: ${({ $theme }) => $theme.textColor};
    display: flex;
    font-family: Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    justify-content: center;
    svg {
      max-height: 1.25rem;
    }
  }

  ${breakpoints.lg} {
    div {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: flex-end;
      width: 100%;
    }
  }

  svg {
    circle {
      stroke: ${({ $theme }) => $theme.textColor};
    }
    path {
      fill: ${({ $theme }) => $theme.textColor};
    }
  }
`;

const NavToggler = styled.button`
  align-items: center;
  background: ${({ $theme }) => $theme.bg};
  cursor: pointer;
  display: flex;
  grid-area: toggle;
  height: 3.75rem;
  justify-content: center;
  justify-self: end;
  width: 3.75rem;
  ${breakpoints.lg} {
    display: none;
  }
`;

const NavBurger = styled.div`
  height: 1rem;
  position: relative;
  width: ${({ navOpen }) => (navOpen === true ? '1rem' : '1.25rem')};
`;

const BurgerBar = styled.span`
  background: ${({ $theme }) => $theme.contrastTextColor};
  content: '';
  display: block;
  height: 0.185rem;
  left: 0;
  position: absolute;
  transform-origin: center center;
  transition: 0.375s;
  width: 1.25rem;
  &:first-child {
    top: ${({ navOpen }) => (navOpen === true ? '50%' : '0')};
    transform: ${({ navOpen }) => (navOpen === true ? 'rotate(45deg)' : 'none')};
  }
  &:nth-child(2) {
    opacity: ${({ navOpen }) => (navOpen === true ? '0' : '1')};
    top: 50%;
    transform: translateY(-50%);
  }
  &:last-child {
    bottom: ${({ navOpen }) => (navOpen === true ? 'initial' : '0')};
    top: ${({ navOpen }) => (navOpen === true ? '50%' : 'initial')};
    transform: ${({ navOpen }) => (navOpen === true ? 'rotate(-45deg)' : 'none')};
  }
`;

const ThemeToggle = styled.div`
  align-items: center;
  background: ${({ open, $theme }) => (open ? $theme.bgLight : 'transparent')};
  cursor: pointer;
  display: flex;
  grid-area: eye;
  height: 3.75rem;
  justify-content: center;
  justify-self: end;
  position: relative;
  transition: 0.375s;
  width: 3.75rem;
  z-index: 1;
  svg {
    height: 1.25rem;
    max-height: 1.25rem;
    path {
      stroke: ${({ $theme }) => $theme.textColor};
      :last-child {
        fill: ${({ $theme }) => $theme.textColor};
      }
    }
  }
  ${breakpoints.lg} {
    grid-column: 12/13;
    height: 4rem;
    justify-self: start;
    width: 4rem;
  }
`;

const NavLink = styled(Link)`
  background: ${({ $theme }) => $theme.bg};
  color: ${({ $theme }) => $theme.contrastTextColor};
  cursor: pointer;
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 3rem;
  padding-top: 0.125rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  ${breakpoints.lg} {
    background: transparent;
    border-bottom: 0.185rem solid transparent;
    color: ${({ $theme }) => $theme.textColor};
    line-height: 1.5rem;
    width: auto;
    &.active {
      border-bottom: 0.185rem solid ${({ $theme }) => $theme.textColor};
    }
    &:nth-child(4) {
      color: ${({ $theme }) => $theme.fg};
      &.active {
        border-bottom: 0.185rem solid ${({ $theme }) => $theme.fg};
      }
    }
  }
`;

const NavLinks = styled(motion.div)`
  align-items: center;
  backdrop-filter: blur(2px);
  box-shadow: 0px 0.185rem 0.75rem rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  grid-area: links;
  grid-column: 2/7;
  grid-gap: 0px;
  height: ${({ windowHeight }) => `calc(${windowHeight}px - 60px)`};
  justify-content: flex-start;
  position: relative;
  z-index: 2;
  ${breakpoints.lg} {
    align-items: center;
    background: transparent;
    box-shadow: none;
    flex-direction: row;
    /* grid-column: 4/10; */
    grid-column: 9/10;
    height: 4rem;
    justify-content: space-around;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0px;
  }
`;

const Background = styled.div`
  background: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : 'white')};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
`;

const StyledNavbar = styled(motion.nav)`
  ${fullGridContainer}
  align-items: center;
  box-shadow: 0px 0.185rem 0.75rem rgba(0, 0, 0, 0.25);
  grid-gap: 0px;
  grid-template-areas:
    'logo logo logo logo eye toggle'
    '. . links links . .';
  grid-template-columns: 1fr 1fr 1fr 1fr 60px 60px;
  height: 3.95rem;
  left: 0;
  padding: 0px;
  position: fixed;
  top: 0;
  transition-duration: 0.75s;
  width: 100%;
  z-index: 5;

  ${breakpoints.lg} {
    box-shadow: ${({ scrolled, themeToggle }) =>
      scrolled || themeToggle ? '0px .185rem .75rem rgba(0,0,0,.25)' : 'transparent'};
    grid-gap: 1.25rem;
    grid-template-areas: 'logo links user eye';
    grid-template-columns: repeat(12, 1fr);
    height: 90px;
  }
`;

export default Navbar;
