import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { breakpoints, gridContainer } from 'styles';
import { LocalContext } from 'context';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';
import {
  Arrow,
  FbIcon,
  EmailIcon,
  TwitterIcon,
  LinkedinIcon,
  Blob1,
  Blob2
} from 'assets/svgs';

const variants = {
  enter: (direction) => ({
    opacity: 0,
    x: direction > 0 ? 100 : -100
  }),
  center: {
    opacity: 1,
    x: 0,
    zIndex: 1
  },
  exit: (direction) => ({
    opacity: 0,
    x: direction < 0 ? 100 : -100,
    zIndex: 0
  })
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => Math.abs(offset) * velocity;

const SpeakerPopup = ({ startPoint, speakers, colors }) => {
  const { mobile, theme } = useContext(LocalContext);
  const [[page, direction], setPage] = useState([startPoint, 0]);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  const speakerIndex = wrap(0, speakers.length, page);

  return (
    <Backdrop>
      <AnimatePresence exitBeforeEnter custom={direction}>
        {!mobile && (
          <>
            <Prev
              $theme={theme}
              onClick={() => paginate(-1)}
              whileTap={{ scale: 0.9 }}
              whileHover={{ opacity: 1 }}
              transition={{ duration: 0.25 }}
              $colors={colors}>
              <Arrow />
            </Prev>
            <Container
              key={page}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{ duration: 0.5 }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);
                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}>
              <Slide $theme={theme}>
                <SpeakerImgContainer colors={colors}>
                  <SpeakerImg image={getImage(speakers[speakerIndex].frontmatter.image)} />
                  <SVG>
                    <Blob1Styled $theme={theme} colors={colors} />
                  </SVG>
                  <SVG>
                    <Blob2Styled $theme={theme} colors={colors} />
                  </SVG>
                </SpeakerImgContainer>
                <SpeakerDetails>
                  <SpeakerName>{speakers[speakerIndex].frontmatter.name}</SpeakerName>
                  <SpeakerPosition dangerouslySetInnerHTML={{ __html: speakers[speakerIndex].frontmatter.position }} />
                  {/* <SpeakerSocials $theme={theme} $colors={colors}>
                    <SpeakerSocial href={speakers[speakerIndex].frontmatter.fbLink}>
                      <FbIcon />
                    </SpeakerSocial>
                    <SpeakerSocial href={speakers[speakerIndex].frontmatter.linkedinLink}>
                      <LinkedinIcon />
                    </SpeakerSocial>
                    <SpeakerSocial href={speakers[speakerIndex].frontmatter.twLink}>
                      <TwitterIcon />
                    </SpeakerSocial>
                    <SpeakerSocial href={speakers[speakerIndex].frontmatter.emailLink}>
                      <EmailIcon />
                    </SpeakerSocial>
                  </SpeakerSocials> */}
                  {/* <SpeakerTopics>
                    {speakers[speakerIndex].frontmatter.topics.map((topic, i) => (
                      <SpeakerTopic key={i}>{topic}</SpeakerTopic>
                    ))}
                  </SpeakerTopics> */}
                  <SpeakerSummary $theme={theme} dangerouslySetInnerHTML={{ __html: speakers[speakerIndex].frontmatter.summary }} />
                </SpeakerDetails>
              </Slide>
            </Container>
            <Next
              $theme={theme}
              onClick={() => paginate(1)}
              whileTap={{ scale: 0.9 }}
              whileHover={{ opacity: 1 }}
              transition={{ duration: 0.25 }}
              $colors={colors}>
              <Arrow />
            </Next>
          </>
        )}

        {mobile && (
          <Container>
            <Slide $theme={theme}>
              <SpeakerImgContainer colors={colors}>
                <SpeakerImg image={getImage(speakers[speakerIndex].frontmatter.image)} />
                <SVG initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  <Blob1Styled $theme={theme} colors={colors} />
                </SVG>
                <SVG initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  <Blob2Styled $theme={theme} colors={colors} />
                </SVG>
              </SpeakerImgContainer>
              <SpeakerDetails>
                <SpeakerName>{speakers[speakerIndex].frontmatter.name}</SpeakerName>
                <SpeakerPosition dangerouslySetInnerHTML={{ __html: speakers[speakerIndex].frontmatter.position }} />
                <SpeakerSocials $colors={colors} $theme={theme}>
                  <SpeakerSocial href={speakers[speakerIndex].frontmatter.fbLink}>
                    <FbIcon />
                  </SpeakerSocial>
                  <SpeakerSocial href={speakers[speakerIndex].frontmatter.linkedinLink}>
                    <LinkedinIcon />
                  </SpeakerSocial>
                  <SpeakerSocial href={speakers[speakerIndex].frontmatter.twLink}>
                    <TwitterIcon />
                  </SpeakerSocial>
                  <SpeakerSocial href={speakers[speakerIndex].frontmatter.emailLink}>
                    <EmailIcon />
                  </SpeakerSocial>
                </SpeakerSocials>
                {/* <SpeakerTopics>
                  {speakers[speakerIndex].frontmatter.topics.map((topic, i) => (
                    <SpeakerTopic key={i}>{topic}</SpeakerTopic>
                  ))}
                </SpeakerTopics> */}
                <SpeakerSummary $theme={theme}>{speakers[speakerIndex].frontmatter.summary}</SpeakerSummary>
              </SpeakerDetails>
            </Slide>
          </Container>
        )}
      </AnimatePresence>
    </Backdrop>
  );
};

const Paginate = styled(motion.button)`
  align-items: center;
  background-color: ${({ $theme, $colors }) =>
    $theme.className === 'contrast' ? 'yellow' : $colors.primary};
  border: none;
  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: center;
  justify-self: end;
  left: initial;
  opacity: 0.8;
  outline: none;
  position: absolute;
  right: initial;
  top: calc(50% - 1.5rem);
  width: 3rem;
  z-index: 3;
  svg {
    transform: rotate(90deg);
    transform-origin: center;
    width: 1.5rem;
    path {
      stroke: ${({ $theme }) => $theme.bgLight};
    }
  }
`;

const Prev = styled(Paginate)`
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  left: 0;
  opacity: 0.8;
  padding-left: 0.625rem;
  svg {
    margin-right: 0.5rem;
    transform: rotate(90deg);
  }
`;

const Next = styled(Paginate)`
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  padding-right: 0.625rem;
  right: 0;
  svg {
    margin-left: 0.5rem;
    transform: rotate(-90deg);
  }
`;

const SpeakerImgContainer = styled.div`
  border: 4px solid ${({ colors }) => colors.primary};
  border-radius: 50%;
  grid-column: 1/7;
  justify-self: center;
  margin-bottom: 1.25rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  margin-top: -50%;
  max-width: 100%;
  position: relative;
  width: 300px;

  ${breakpoints.lg} {
    align-self: center;
    grid-column: 1/5;
    height: auto;
    margin-left: -50%;
    margin-right: 50%;
    margin-top: 0px;
    max-width: 100%;
    width: 300px;
  }
`;

const SpeakerImg = styled(GatsbyImage)`
  border-radius: 50%;
  box-shadow: 0px 0.375rem 0.75rem rgba(0, 0, 0, 0.25);
  cursor: pointer;
  height: auto;
  min-height: 275px;
  object-fit: cover !important;
  object-position: center !important;
  position: relative;
  width: 100%;
  z-index: 2;
  ${breakpoints.lg} {
    min-height: 300px;
  }
`;

const SpeakerName = styled.h3`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: left;
`;

const SpeakerPosition = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin-bottom: 0.375rem;
  margin-top: 0.25rem;
  text-align: left;
  width: 100%;
`;

const SpeakerSocial = styled.a`
  margin-right: 0.625rem;
`;

const SpeakerSocials = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
  ${SpeakerSocial} {
    svg {
      width: 1.5rem;
      circle {
        fill: ${({ $theme, $colors }) =>
          $theme.className === 'contrast' ? 'yellow' : $colors.secondary};
        transition: 0.5s;
      }
      path {
        fill: ${({ $theme }) => $theme.bgLight};
        transition: 0.5s;
      }
    }
    :last-child {
      svg {
        path {
          fill: none;
          stroke: ${({ $theme }) => $theme.bgLight};
          transition: 0.5s;
        }
      }
    }
    :hover {
      svg {
        circle {
          fill: ${({ $colors }) => $colors.primary};
        }
        path {
          fill: ${({ $theme }) => $theme.bgLight};
        }
      }
      :last-child {
        svg {
          path {
            fill: none;
            stroke: ${({ $theme }) => $theme.bgLight};
            transition: 0.5s;
          }
        }
      }
    }
  }
`;

const SpeakerTopics = styled.p`
  font-size: 1.25rem;
  margin-bottom: 1rem;
  margin-top: auto;
`;

const SpeakerTopic = styled.span`
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0;
  margin-right: 0.375rem;
  margin-top: auto;
  &:after {
    content: ',';
  }
  &:last-child {
    &:after {
      content: '';
    }
  }
`;

const SpeakerSummary = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  margin-top: 1.25rem;
`;

const SpeakerDetails = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  margin-left: 0px;

  ${breakpoints.lg} {
    grid-column: 5/11;
    margin-left: -150px;
    padding: 2.5rem;
  }
`;

const SVG = styled(motion.div)`
  bottom: 0;
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  transition: 1s;
  width: auto;
  z-index: 0;
  :nth-child(5) {
    z-index: 5;
  }
  svg {
    path {
      transition: 0.5s;
    }
  }
`;

const Blob1Styled = styled(Blob1)`
  height: 100%;
  left: -15%;
  position: absolute;
  top: -10%;
  path {
    ${({ $theme }) =>
      $theme.className === 'contrast' && `fill: ${$theme.textColor} !important;`}
  }
`;

const Blob2Styled = styled(Blob2)`
  bottom: -12.5%;
  height: 85%;
  position: absolute;
  right: -10%;
  /* transform: rotate(160deg); */
  transition-duration: 1s;
  path {
    ${({ $theme }) =>
      $theme.className === 'contrast' && `fill: ${$theme.textColor} !important;`}
  }
`;

const Slide = styled.div`
  align-items: center;
  ${gridContainer}
  background: ${({ $theme }) => $theme.bgLight};
  color: ${({ $theme }) => $theme.textColor};
  height: auto;
  margin-bottom: 3rem;
  margin-top: 10rem;
  max-width: 100%;
  padding: 1.25rem;
  position: relative;
  width: 1200px;
  ${breakpoints.lg} {
    margin-left: 200px;
    margin-right: 5rem;
    margin-top: 0px;
  }
`;

const Container = styled(motion.div)`
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 100%;
  padding-top: 1rem;
  width: 1200px;
  ${breakpoints.sm} {
    max-width: 400px;
  }
  ${breakpoints.lg} {
    max-width: 100%;
    padding-top: 0;
  }
`;

const Backdrop = styled.div`
  height: auto;
  width: auto;
  @media (max-width: 1150px) {
    overflow-x: hidden;
  }
`;

export default SpeakerPopup;
