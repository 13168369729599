const firebaseConfig = {
  apiKey: "AIzaSyDsr7uTyFgL8ZW6-OAZOEIodvipbAsBzL4",
  authDomain: "eie-workshops.firebaseapp.com",
  databaseURL: "https://eie-workshops-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "eie-workshops",
  storageBucket: "eie-workshops.appspot.com",
  messagingSenderId: "871572868837",
  appId: "1:871572868837:web:f5aac7ad2cab55aa03ce0c"
};

export default firebaseConfig;
