import React, { useContext, useEffect, useState, useRef } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { H1, H5, breakpoints, Grid } from 'styles';
import { DateButton, Interaction, LivestreamOverlays, Button } from 'components';
import Vimeo from '@vimeo/player';
import { useIntersection } from 'hooks';
import { scrollToAnchor } from 'utils';
import { LocalContext, FirebaseContext } from 'context';
import { Markup } from 'interweave';

const Livestream = ({
  colors,
  eventName,
  eventStartTime,
  eventEndTime,
  eventLocation,
  eventDescription,
  eventDates,
  eventStreamUrl,
  eventStreamPlaceholderImg,
  eventBanner,
  eventBannerVideo
}) => {
  const bannerImage = getImage(eventBanner);
  const { theme, streamRef } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const [vimeoPlayer, setVimeoPlayer] = useState(null);
  const [livestreamIsPlaying, setLivestreamIsPlaying] = useState(false);
  const [pipRef, vimeoPlayerObserver] = useIntersection({
    initialInView: true,
    threshold: 0.75
  });
  const [isPipModeEnabled, setIsPipModeEnabled] = useState(false);
  const [questionCurrentlyBeingAnsweredLive, setQuestionCurrentlyBeingAnsweredLive] =
    useState(null);
  // const [lastFetchedParticipantDoc, setLastFetchedParticipantDoc] = useState();

  const livestreamRef = useRef(null);

  useEffect(
    () =>
      scrollToAnchor('livestream', {
        offset: -170,
        duration: 1500,
        delay: 1350
      }),
    []
  );

  /* TODO: Refactor/fix this */
  // useEffect(() => {
  //   const keyDownListener = (e) => {
  //     if (e.code === 'Enter' || e.code === 'NumpadEnter') {
  //       if (activeTab === 'Chat') {
  //         handleUserCommentSubmit(e);
  //       } else if (activeTab === 'Q&A') {
  //         console.log('WHOAH')
  //         handleSubmitNewQuestion(e);
  //       }
  //     }
  //   };
  //   document.addEventListener('keydown', keyDownListener);
  //   return () => {
  //     console.log('REMOVED')
  //     document.removeEventListener('keydown', keyDownListener);
  //   };
  // }, [activeTab, userCommentText, textArea]);

  // useEffect(() => {
  //   let unsubscribeFromEventUpdates;

  //   if (firebase) {
  //     unsubscribeFromEventUpdates = firebase.subscribeToEventUpdates({
  //       eventName,
  //       onSnapshot: (snapshot) => {
  //         if (!snapshot.empty) {
  //           const { questionCurrentlyBeingAnsweredLive: _questionCurrentlyBeingAnsweredLive } =
  //             snapshot.data();
  //           if (_questionCurrentlyBeingAnsweredLive) {
  //             setQuestionCurrentlyBeingAnsweredLive(_questionCurrentlyBeingAnsweredLive);
  //           } else {
  //             setQuestionCurrentlyBeingAnsweredLive(null);
  //           }
  //         } else if (snapshot.empty) {
  //           /* CONOR TODO: I don't this this conditional/error is necessary anymore? */
  //           console.error(
  //             "You need to add a doc for this event in the 'Events' collection in Firebase."
  //           );
  //         }
  //       }
  //     });
  //     return () => {
  //       if (unsubscribeFromEventUpdates) {
  //         unsubscribeFromEventUpdates();
  //       }
  //     };
  //   }
  // }, [firebase]);

  useEffect(() => {
    const livestreamIframe = document.getElementById('livestream-iframe');

    if (livestreamIframe) {
      const player = new Vimeo(livestreamIframe);

      setVimeoPlayer(player);

      player.on('play', () => {
        setLivestreamIsPlaying(true);
      });
      player.on('pause', () => {
        setLivestreamIsPlaying(false);
      });
      player.on('enterpictureinpicture', () => {
        setIsPipModeEnabled(true);
      });
      player.on('leavepictureinpicture', () => {
        setIsPipModeEnabled(false);
      });
    }
  }, []);

  useEffect(() => {
    if (
      document.pictureInPictureEnabled &&
      vimeoPlayer &&
      !vimeoPlayer.disablePictureInPicture &&
      livestreamIsPlaying &&
      !vimeoPlayerObserver.intersecting
    ) {
      try {
        vimeoPlayer.requestPictureInPicture();
      } catch (err) {
        console.error(err);
      }
    }
  }, [vimeoPlayer, vimeoPlayerObserver]);

  const handlePipOverlayClick = () => vimeoPlayer.exitPictureInPicture();

  return (
      <Container colors={colors}>
        <Banner>
          {eventBannerVideo && (
            <BannerVideo autoPlay muted id="bgvid">
              <source src={eventBannerVideo} type="video/mp4" />
            </BannerVideo>
          )}
          <BannerImage image={bannerImage} alt={`${eventName} Stream Placeholder`} />
        </Banner>
        <Details>
          <div ref={streamRef} style={{ position: 'absolute', top: '-4.25rem' }} />
          <Dates $theme={theme} colors={colors}>
            <Markup content={eventDates} />
          </Dates>
          <SiteTitle $theme={theme} colors={colors}>
            <Markup content="Epilepsy In English Workshops" />
          </SiteTitle>
          <WorkshopTitle $theme={theme} colors={colors}>
            <Markup content="Gene Therapy" />
          </WorkshopTitle>
        </Details>
        <LivestreamAndInteraction>
          <LiveStream ref={livestreamRef} id="livestream">
            <div
              ref={pipRef}
              style={{
                padding: '56.25% 0 0 0',
                height: '100%',
                position: 'relative',
                overflow: 'hidden'
              }}>
              {eventStreamPlaceholderImg ? (
                <div
                  style={{
                    backgroundColor: '#fff',
                    border: '1px solid #bdbdbd',
                    height: '100%',
                    left: 0,
                    position: 'absolute',
                    top: 0,
                    width: '100%'
                  }}>
                  <GatsbyImage
                    image={getImage(eventStreamPlaceholderImg)}
                    alt={`${eventName} Stream Placeholder`}
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
              ) : (
                <>
                  <iframe
                    title={eventName}
                    id="livestream-iframe"
                    src={`${eventStreamUrl}?pip=1&autoplay=1&fullscreen=1&controls=1`}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%'
                    }}
                  />
                  <LivestreamOverlays
                    colors={colors}
                    eventName={eventName}
                    handlePipOverlayClick={handlePipOverlayClick}
                    isPipModeEnabled={isPipModeEnabled}
                    livestreamRef={livestreamRef}
                    questionCurrentlyBeingAnsweredLive={questionCurrentlyBeingAnsweredLive}
                  />
                </>
              )}
            </div>
          </LiveStream>
          <Interaction
            colors={colors}
            eventName={eventName}
            questionCurrentlyBeingAnsweredLive={questionCurrentlyBeingAnsweredLive}
          />
        </LivestreamAndInteraction>
        {/* <Grid>
        <StreamLinks>
          <DateButton
            eventName={eventName}
            eventDescription={eventDescription}
            eventLocation={eventLocation}
            eventEndTime={eventEndTime}
            eventStartTime={eventStartTime}
            colors={colors}
          />
        </StreamLinks>
      </Grid> */}
      </Container>
  );
};

const StreamLinks = styled.div`
  grid-column: 1/7;
  margin-top: 1.5rem;
  ${breakpoints.sm} {
    grid-column: 1/5;
    margin-top: 3rem;
  }
`;

const Dates = styled(H5)`
  color: ${({ colors, $theme }) => ($theme.className === 'contrast' ? $theme.fg : colors.tertiary)};
  margin-bottom: 0;
  font-weight: 600;
`;

const SiteTitle = styled(H1)`
  color: ${({ colors, $theme }) =>
    $theme.className === 'contrast' ? $theme.textColor : colors.primary};
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0;
  ${breakpoints.lg} {
    font-size: 2rem;
  }
`;

const WorkshopTitle = styled(H1)`
  color: ${({ colors, $theme }) =>
    $theme.className === 'contrast' ? $theme.textColor : colors.primary};
  font-size: 2rem;
  margin-bottom: 0;
  ${breakpoints.lg} {
    font-size: 3rem;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  margin-bottom: 1rem;
  position: relative;
  z-index: 2;
  ${breakpoints.sm} {
    grid-column: 2/5;
    margin-bottom: 1rem;
    margin-top: 3rem;
  }
  ${breakpoints.lg} {
    grid-column: 2/8;
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
`;

const LiveStream = styled.div`
  height: auto;
  margin: 1.25rem -0.75rem;
  overflow: hidden;
  position: relative;
  z-index: 2;
  ${breakpoints.sm} {
    margin: 1.25rem 0;
  }
  ${breakpoints.lg} {
    height: 100%;
    margin: 0px;
  }
`;

const BannerImage = styled(GatsbyImage)`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 0;
`;

const BannerVideo = styled.video`
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const Banner = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 0;
`;

const LivestreamAndInteraction = styled.div`
  display: grid;
  grid-column: 1/7;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  width: 100%;
  ${breakpoints.sm} {
    grid-column: 2/6;
  }
  ${breakpoints.lg} {
    grid-column: 1/13;
    grid-template-columns: 2fr 1fr;
  }
`;

const Container = styled.div`
  color: #00004e;
  display: grid;
  grid-column: 1/7;
  grid-gap: 1rem;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  height: auto;
  margin: 0 auto;
  padding: 1.25rem 1.25rem 2.5rem;
  position: relative;
  width: 100%;
  ${breakpoints.lg} {
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
    padding: 1.25rem 1.25rem 0;
  }
`;

export default Livestream;
