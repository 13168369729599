import React, { useState } from 'react';
import styled from 'styled-components';
import { fullGridContainer, breakpoints, defaultColors } from 'styles';
import { wrap } from '@popmotion/popcorn';
import { useWindowSize } from 'hooks';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { LeftArrow, RightArrow } from 'assets/svgs';
import { Markup } from 'interweave';

const VideoCarousel = ({ carouselVideos, onHomepage }) => {
  const colors = defaultColors;

  const [currrentId, setCurrentId] = useState(carouselVideos[0].id);
  const [currentStream, setCurrentStream] = useState(carouselVideos[0].url);
  const [currentVideoTitle, setCurrentVideoTitle] = useState(carouselVideos[0].title);
  const [[page], setPage] = useState([0, 0]);

  const { windowWidth } = useWindowSize();

  const paginate = (dir) => {
    setPage([page + dir, dir]);
  };

  const firstThumb = wrap(0, carouselVideos.length, page);
  const secondThumb = wrap(0, carouselVideos.length, page + 1);
  const thirdThumb = wrap(0, carouselVideos.length, page + 2);
  const fourthThumb = wrap(0, carouselVideos.length, page + 3);

  const thumbnail1 = getImage(carouselVideos[firstThumb].thumb);
  const thumbnail2 = getImage(carouselVideos[secondThumb].thumb);
  const thumbnail3 = getImage(carouselVideos[thirdThumb].thumb);
  const thumbnail4 = getImage(carouselVideos[fourthThumb].thumb);

  const handleThumbClick = (video) => {
    setCurrentStream(video.url);
    setCurrentId(video.id);
    setCurrentVideoTitle(video.title);
  };

  return (
    <Container id="livestream" colors={colors}>
      <LiveStream>
        <div style={{ padding: '56.25% 0 0 0', height: '100%', position: 'relative' }}>
          <iframe
            title={currentVideoTitle}
            src={currentStream}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }}
          />
        </div>
      </LiveStream>
      <Thumbs onHomepage={onHomepage}>
        {windowWidth < 768 && (
          <Prev colors={colors} onClick={() => paginate(-1)}>
            <LeftArrow />
          </Prev>
        )}
        <Thumb onClick={() => handleThumbClick(carouselVideos[firstThumb])}>
          <ImageContainer>
            <Overlay active={carouselVideos[firstThumb].id === currrentId}>Now Playing</Overlay>
            <GatsbyImage image={thumbnail1} alt={carouselVideos[firstThumb].title} />
          </ImageContainer>
          <ThumbTitle colors={colors}>{carouselVideos[firstThumb].title}</ThumbTitle>
          <ThumbDescription colors={colors}>
            <Markup content={carouselVideos[firstThumb].description} />
          </ThumbDescription>
        </Thumb>
        {windowWidth >= 768 && (
          <>
            <Thumb onClick={() => handleThumbClick(carouselVideos[secondThumb])}>
              <ImageContainer>
                <Overlay active={carouselVideos[secondThumb].id === currrentId}>
                  Now Playing
                </Overlay>
                <GatsbyImage image={thumbnail2} alt={carouselVideos[secondThumb].title} />
              </ImageContainer>
              <ThumbTitle colors={colors}>{carouselVideos[secondThumb].title}</ThumbTitle>
              <ThumbDescription colors={colors}>
                <Markup content={carouselVideos[secondThumb].description} />
              </ThumbDescription>
            </Thumb>
            <Thumb onClick={() => handleThumbClick(carouselVideos[thirdThumb])}>
              <ImageContainer>
                <Overlay active={carouselVideos[thirdThumb].id === currrentId}>Now Playing</Overlay>
                <GatsbyImage image={thumbnail3} alt={carouselVideos[thirdThumb].title} />
              </ImageContainer>
              <ThumbTitle colors={colors}>{carouselVideos[thirdThumb].title}</ThumbTitle>
              <ThumbDescription colors={colors}>
                <Markup content={carouselVideos[thirdThumb].description} />
              </ThumbDescription>
            </Thumb>
            {onHomepage && (
              <Thumb onClick={() => handleThumbClick(carouselVideos[fourthThumb])}>
                <ImageContainer>
                  <Overlay active={carouselVideos[fourthThumb].id === currrentId}>
                    Now Playing
                  </Overlay>
                  <GatsbyImage image={thumbnail4} alt={carouselVideos[fourthThumb].title} />
                </ImageContainer>
                <ThumbTitle colors={colors}>{carouselVideos[fourthThumb].title}</ThumbTitle>
                <ThumbDescription colors={colors}>
                  <Markup content={carouselVideos[fourthThumb].description} />
                </ThumbDescription>
              </Thumb>
            )}
          </>
        )}
        {windowWidth < 768 && (
          <Next colors={colors} onClick={() => paginate(1)}>
            <RightArrow />
          </Next>
        )}
      </Thumbs>
    </Container>
  );
};

const Prev = styled.div`
  align-items: center;
  background-color: ${({ colors }) => colors.tertiary};
  border: none;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 1.75rem;
  justify-content: center;
  justify-self: end;
  left: 0.5rem;
  opacity: 0.8;
  outline: none;
  padding-left: 0.425rem;
  position: absolute;
  top: 42.5%;
  transform: translateY(-50%);
  transition: 0.5s;
  width: 1.75rem;

  @media (min-width: 768px) {
    left: 2rem;
    /* top: 8rem; */
  }

  @media (min-width: 1024px) {
    width: 2rem;
    height: 2rem;
  }

  svg {
    path {
      stroke: rgb(245, 250, 250);
    }
  }

  :hover {
    opacity: 1;
  }
`;

const Next = styled.div`
  align-items: center;
  background-color: ${({ colors }) => colors.tertiary};
  border: none;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 1.75rem;
  justify-content: center;
  justify-self: start;
  opacity: 0.8;
  outline: none;
  padding-right: 0.625rem;
  position: absolute;
  right: 0.5rem;
  top: 42.5%;
  transform: translateY(-50%);
  transition: 0.5s;
  width: 1.75rem;

  @media (min-width: 768px) {
    /* top: 8rem; */
    right: 2rem;
  }

  @media (min-width: 1024px) {
    width: 2rem;
    height: 2rem;
  }

  svg {
    path {
      stroke: rgb(245, 250, 250);
    }
  }

  :hover {
    opacity: 1;
  }
`;

const ThumbDescription = styled.p`
  color: #3c3c3c;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5em;
  text-align: left !important;

  a {
    color: ${({ colors }) => colors.secondary};
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 1rem;
  }
`;

const ThumbTitle = styled.h3`
  color: ${({ colors }) => colors.secondary};
  font-size: 0.975rem;
  font-weight: 600;
  line-height: 1.5em;
  text-align: left !important;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 1.25rem;
  }
`;

const Overlay = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: ${({ active }) => (active ? 1 : 0)};
  position: absolute;
  text-transform: uppercase;
  top: 0;
  transition: 0.5s;
  width: 100%;
  z-index: ${({ active }) => (active ? 2 : -1)};
`;

const ImageContainer = styled.div`
  height: 100%;
  margin-bottom: 1.5rem;
  /* max-height: 150px; */
  position: relative;
  transition: 0.5s;
  /* max-width: 216px; */

  @media screen and (min-width: 768px) {
    max-width: ${({ onHomepage }) => onHomepage ? '216px' : '280px'} ;
  }

  h3 {
    color: white;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .gatsby-image-wrapper {
    height: 100%;
    /* max-height: 150px; */
    opacity: 0.75;
    transition: 0.5s;
    width: 100%;
    img {
      object-position: center;
    }
  }
`;

const Thumb = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: auto;
  outline: none;
  position: relative;
  transition: 0.5s;
  width: 100%;

  &:hover {
    outline: none;
    border: none;
    .gatsby-image-wrapper {
      opacity: 1;
    }
  }

  &:focus {
    border: none;
    outline: none;
  }
`;

const Thumbs = styled.div`
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  display: grid;
  grid-column: 1/7;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  justify-content: center;
  padding: 3rem;
  position: relative;

  @media screen and (min-width: 550px) {
    grid-column: 2/6;
  }

  @media (min-width: 768px) {
    /* grid-column: 1/7; */
    align-items: start;
    grid-template-columns: ${({ onHomepage }) => onHomepage ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)'};
    grid-column: 1/12;
    padding: 3rem 5rem;
  }

  @media (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

const LiveStream = styled.div`
  grid-column: 1/7 !important;
  width: 100% !important;

  @media screen and (min-width: 1150px) {
    grid-column: 2/12 !important;
  }
`;

const Container = styled.div`
  ${fullGridContainer}
  color: #00004E;
  grid-gap: 0;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0;
  position: relative;
  width: 100%;

  @media (min-width: ${breakpoints.lg}) {
    grid-gap: 0;
  }
`;

export default VideoCarousel;
