import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button, BulletPoint } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { useStaticQuery, graphql } from 'gatsby';
import { flexContainer, gridContainer, fullGridContainer, breakpoints } from 'styles';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import {
  FbIcon,
  InstaIcon,
  LinkedinIcon,
  TwitterIcon,
  LogoWhite,
  AgencyxLogo,
  CampaignxLogo,
  EventxLogo,
  AgencyxLogoContrast,
  GetBroadcastingLogo,
  MobIcon,
  LocoIcon
} from 'assets/svgs';

const HubFooter = () => {
  const { theme, contactRef } = useContext(LocalContext);
  const { user } = useContext(FirebaseContext);

  return (
    <>
      {/* <FlexContainer>
        <Title>
          {user && (
            <BulletPoint
              top="-1rem"
              left="1.25rem"
              content={`
              <h4>Our Sponsors / Partners Section<br/> - More than Logos!</h4>
              <p>Event X Live events give sponsors and partners great opportunities to build brand engagement. As all attendees are online anyhow, it’s an easier consideration to engage digitally with sponsors and partners.</p>
              <p>Along with sponsor logos, we can play video and ads from their portfolio, they can sponsor show segments, we can showcase their latest products, branded imagery on all event X promotional material, digital media pack of assets to share re their event, at event virtual stage branding, downloadable content and links to their own websites as well as branding all post show content.</p>
              <p>We can encourage social media connections, run at event competitions, research and surveys and allow options for event participants to subscribe to sponsors databases directly. Customised analytic reporting can also be made available post event for sponsors to calculate their ROI.</p>
            `}
            />
          )}
          <H3>Our Partners</H3>
        </Title>
        <Partners $theme={theme}>
          <AgencyxLogo />
          <CampaignxLogo />
          <EventxLogo />
          <GetBroadcastingLogo />
        </Partners>
      </FlexContainer> */}
      {/* <Donations $theme={theme}>
        {user && (
          <BulletPoint
            top="-1rem"
            bottom="1rem"
            right="0rem"
            content={`
            <p>For our charity and not for profit clients there is an option to Make a Donation running through all stages of the event, making it easy for the audience to contribute.</p>
            `}
          />
        )}

        <div>
          <H3 style={{ marginBottom: '0px', width: '100%' }}>Make A Donation</H3>
          <p>
            For Charity and Not-For-Profit events consider a `Make a Donation CTA` which can be
            simply linked to your existing online payment process.
          </p>
        </div>
        <Button width="12rem">Donate</Button>
      </Donations> */}
      <GridContainer $theme={theme}>
        <Subscribe $theme={theme}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              maxWidth: '410px',
              marginBottom: '1.5rem'
            }}>
            <a href="https://www.epilepsyinenglish.ie/" target="blank">
              <StaticImage
                src="../../../assets/images/logos/eie-logo-white.png"
                width="304"
                alt="EIE logo"
              />
            </a>
            <a
              href="https://www.futureneurocentre.ie/"
              target="blank"
              style={{ marginLeft: '10px' }}>
              <StaticImage
                src="../../../assets/images/logos/future-neuro-logo-white.png"
                width="96"
                alt="Future Neuro logo"
              />
            </a>
          </div>
          <P style={{ marginBottom: '30px' }}>
            Epilepsy in English Workshops virtual event is brought to you by Agency X in conjunction
            with Epilepsy in English, Future Neuro, RCSI, Epilepsy Ireland, DCU, and SFI.
          </P>
          <a href="https://agencyx.ie" target="blank">
            <AgencyxLogoContrast className="ax-logo" width={174} />
          </a>
        </Subscribe>
        <Contact id="contact" ref={contactRef}>
          <H3>Tech Support</H3>
          <ContactDetail $theme={theme}>Email: support@agencyx.ie</ContactDetail>
          <H3>Contact us</H3>
          <ContactDetail $theme={theme}>
            FutureNeuro
            <br />
            RCSI
            <br />
            123 St. Stephen’s Green
            <br />
            Dublin 2, D02 YN77
            <br />
          </ContactDetail>
          <ContactDetail $theme={theme}>Email: workshops@epilepsyinenglish.ie</ContactDetail>
        </Contact>
        <Socials>
          <H3>Follow us on Social</H3>
          <div>
            <SocialLink
              $theme={theme}
              href="https://www.facebook.com/FutureNeuroCentre"
              target="blank">
              <FbIcon />
            </SocialLink>
            <SocialLink $theme={theme} href="https://twitter.com/Futureneuro_ie" target="blank">
              <TwitterIcon />
            </SocialLink>
            <SocialLink
              $theme={theme}
              href="https://www.linkedin.com/company/futureneuro/"
              target="blank">
              <LinkedinIcon />
            </SocialLink>
            {/* <SocialLink
              $theme={theme}
              href="https://www.instagram.com/agency_x_dublin/"
              target="blank">
              <InstaIcon />
            </SocialLink> */}
          </div>
        </Socials>
      </GridContainer>
      <FooterInfo>
        {/* <FooterLogos>
          <a href="https://www.rcsi.com/dublin" target="_blank" rel="noreferrer">
            <StaticImage
              src="../../../assets/images/logos/rcsi-logo.png"
              width="66"
              alt="RCSI Logo"
            />
          </a>
          <a href="https://www.epilepsy.ie/" target="_blank" rel="noreferrer">
            <StaticImage
              src="../../../assets/images/logos/epilepsy-ireland-logo.png"
              width="61"
              alt="Epilepsy Ireland logo"
            />
          </a>
          <a href="https://www.dcu.ie/" target="_blank" rel="noreferrer">
            <StaticImage
              src="../../../assets/images/logos/dcu-logo.png"
              width="35"
              alt="DCU Logo"
            />
          </a>
          <a href="https://www.sfi.ie/" target="_blank" rel="noreferrer">
            <StaticImage
              src="../../../assets/images/logos/sfi-logo.png"
              width="76"
              alt="SFI Logo"
            />
          </a>
        </FooterLogos> */}
        <FooterCopyright $theme={theme}>
          Copyright © 2021 - Epilepsy in English - Designed and Developed by Agency X{' '}
        </FooterCopyright>
        <FooterTerms $theme={theme}>
          {/* <a href="/">Terms & Conditions</a> */}
          <a
            style={{ textDecoration: 'underline' }}
            href="https://www.rcsi.com/dublin/privacy-policy"
            rel="noreferrer"
            target="_blank">
            Privacy Policy
          </a>
        </FooterTerms>
      </FooterInfo>
    </>
  );
};

const Logo = styled(GatsbyImage)`
  margin-left: -0.3rem;
  margin-bottom: 1rem;
  max-width: 12.375rem;
  width: 100%;

  ${breakpoints.lg} {
    max-width: 25.875rem;
  }
`;

const Logo2 = styled(GatsbyImage)`
  margin-left: -0.3rem;
  margin-bottom: 1rem;
  max-width: 12.375rem;
  width: 100%;

  ${breakpoints.lg} {
    max-width: 25.875rem;
  }
`;

const FlexContainer = styled.section`
  ${flexContainer}
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;
  margin-top: 6rem;
`;
const GridContainer = styled.footer`
  background: ${({ $theme }) => $theme.bg};
  ${fullGridContainer}
  color: ${({ $theme }) => $theme.contrastTextColor};
  grid-row-gap: 3.75rem;
  padding: 2.25rem 1.25rem;
  position: relative;

  ${breakpoints.sm} {
    padding: 40px;
  }
  ${breakpoints.lg} {
    padding: 0;
    padding-bottom: 5rem;
    padding-top: 2.25rem;
  }
`;

const H3 = styled.h3`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  margin-bottom: 0.6rem;
  margin-top: 0px;
`;

const P = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin-top: 0px;
`;

const Partners = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: center;
  margin: 2rem auto;
  padding: 0px 1.25rem;
  width: 100%;
  svg {
    height: 1.5rem;
    margin: 2rem;
    width: auto;
    path {
      fill: ${({ $theme }) => ($theme.className === 'contrast' ? 'yellow' : 'auto')};
    }
    :last-child {
      height: 5rem;
    }
  }
`;

const Donations = styled(FlexContainer)`
  align-items: center;
  background-color: ${({ $theme }) => $theme.bgLight};
  color: #000;
  h3,
  p {
    color: ${({ $theme }) => $theme.textColor};
  }
  display: grid;
  grid-gap: 0.625rem;
  grid-template-columns: repeat(6, 1fr);
  margin: 1.25rem;
  max-width: 100%;
  padding: 1.25rem 2.25rem;
  position: relative;
  text-align: center;
  div {
    grid-column: 1/7;
  }
  p {
    grid-column: 1/7;
    margin-bottom: 0.625rem;
    margin-top: 0.625rem;
  }
  button {
    grid-column: 1/8;
    justify-self: center;
  }
  ${breakpoints.sm} {
    grid-column: 1/7;
    grid-gap: 1.25rem;
    margin: 2.5rem 1.25rem;
    text-align: left;
    div {
      grid-column: 1/5;
    }
    p {
      margin-bottom: 0px;
    }
    button {
      grid-column: 5/7;
      justify-self: end;
    }
  }
  ${breakpoints.lg} {
    grid-column: 2/12;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(10, 1fr);
    margin: 2.5rem auto;
    text-align: left;
    div {
      grid-column: 1/9;
    }
    p {
      grid-column: 1/9;
      margin-bottom: 0px;
    }
    button {
      grid-column: 9/11;
    }
  }
`;

const Subscribe = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  svg {
    margin-bottom: 2rem;
    max-width: 225px;
    path {
      fill: ${({ $theme }) => ($theme.className === 'contrast' ? 'yellow' : 'auto')};
    }
  }
  ${breakpoints.lg} {
    grid-column: 2/5;
  }
`;

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  ${breakpoints.lg} {
    grid-column: 6/9;
  }
`;

const ContactDetail = styled.a`
  align-items: center;
  color: ${({ $theme }) => $theme.contrastTextColor};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1.25rem;
  text-decoration: none;
  transition: 0.5s;
  :visited {
    color: ${({ $theme }) => $theme.contrastTextColor};
  }
  svg {
    height: 1.75rem;
    margin-right: 0.75rem;
    min-width: 1.75rem;
    width: 1.75rem;
    circle {
      fill: ${({ $theme }) => ($theme.className === 'contrast' ? 'yellow' : $theme.fg)};
      transition: 0.5s;
    }
    path {
      stroke: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : 'white')};
      transition: 0.5s;
    }
  }
  &:hover {
    color: ${({ $theme }) => $theme.fg};
    svg {
      circle {
        fill: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : 'white')};
      }
      path {
        stroke: ${({ $theme }) => ($theme.className === 'contrast' ? 'yellow' : $theme.fg)};
      }
    }
  }
`;

const Socials = styled.div`
  grid-column: 1/7;
  div {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  h3 {
    width: 100%;
  }
  ${breakpoints.lg} {
    grid-column: 9/12;
  }
`;

const SocialLink = styled.a`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1.25rem;
  transition: 0.5s;
  svg {
    height: 2.5rem;
    margin-right: 1.5rem;
    width: 2.5rem;
    circle {
      fill: ${({ $theme }) => ($theme.className === 'contrast' ? 'yellow' : $theme.fg)};
      transition: 0.5s;
    }
    path {
      fill: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : 'white')};

      transition: 0.5s;
    }
  }
  &:hover {
    color: ${({ $theme }) => $theme.fg};
    svg {
      circle {
        fill: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : 'white')};
      }
      path {
        fill: ${({ $theme }) => ($theme.className === 'contrast' ? 'white' : $theme.fg)};
      }
    }
  }
`;

const FooterInfo = styled.footer`
  ${gridContainer}
  align-items: start;
  grid-gap: 0.4rem 1.25rem !important;
  padding: 1rem 1.25rem;
  ${breakpoints.sm} {
    grid-column: 1/7;
  }
  ${breakpoints.lg} {
    grid-column: 2/13;
    padding: 9px 0px;
  }
`;

const FooterLogos = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  grid-column: 1/7;
  justify-content: space-between;
  /* margin-top: 0.5rem; */
  width: 330px;

  /* img {
  object-fit: contain !important;
 } */

  /* > div:nth-of-type(1) {
    width: 66px;
  }
  > div:nth-of-type(2) {
    width: 61px;
  }
  > div:nth-of-type(3) {
    width: 35px;
  }
  > div:nth-of-type(4) {
    width: 76px;
  } */
`;

const FooterCopyright = styled.p`
  color: ${({ $theme }) => $theme.textColor};

  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  grid-column: 1/7;
  line-height: 1.25rem;
  margin-top: 0px;
  ${breakpoints.lg} {
    grid-column: 1/8;
  }
`;

const FooterTerms = styled.div`
  display: flex;
  grid-column: 1/3;
  justify-content: flex-start;
  a {
    color: ${({ $theme }) => $theme.textColor};
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    margin-right: 1.25rem;
  }
  ${breakpoints.lg} {
    grid-column: 9/11;
  }
`;

const Title = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
`;

export default HubFooter;
