import React, { useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { Arrow } from 'assets/svgs';
import { useEventData } from 'hooks';
import { Link } from 'gatsby';
import { breakpoints } from 'styles';

const EventList = ({ toggleNav }) => {
  const { theme } = useContext(LocalContext);
  const { edges: events } = useEventData();
  const [expanded, setExpanded] = useState(0);
  const dropdownToggle = () => {
    setExpanded(expanded === 0 ? 1 : 0);
  };

  const currentEvents = events
    .filter(({ node }) => node.frontmatter.status === 'upcoming')
    .sort((a, b) => b.node.frontmatter.eventId - a.node.frontmatter.eventId);
  const pastEvents = events
    .filter(({ node }) => node.frontmatter.status === 'previous')
    .sort((a, b) => b.node.frontmatter.eventId - a.node.frontmatter.eventId);

  return (
    <Dropdown
      expanded={expanded === 1}
      onMouseEnter={() => {
        setExpanded(1);
      }}
      onMouseLeave={() => {
        setExpanded(0);
      }}>
      <Heading onClick={dropdownToggle} expanded={expanded === 1} $theme={theme}>
        <DownArrow expanded={expanded === 0 ? 1 : 0} $theme={theme} />
        Events
      </Heading>

      <ContentContainer expanded={expanded === 1} $theme={theme}>
        <AnimatePresence initial={false}>
          {expanded && (
            <Content
              $theme={theme}
              onClick={() => {
                dropdownToggle();
                toggleNav();
              }}
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 }
              }}
              transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}>
              {currentEvents.length > 0 && (
                <>
                  <h4>Upcoming</h4>
                  {currentEvents
                    .map((event, i) => (
                      <StyledLink
                        key={i}
                        to={`/events/${event.node.frontmatter.slug}`}
                        title={event.node.frontmatter.name}
                        $theme={theme}
                        $colors={event.node.frontmatter.colors}>
                        {event.node.frontmatter.name}
                      </StyledLink>
                    ))
                    .slice(0, 5)}
                </>
              )}
              {pastEvents.length > 0 && (
                <>
                  <h4>Previous</h4>
                  {pastEvents
                    .map((event, i) => (
                      <StyledLink
                        key={i}
                        to={`/events/${event.node.frontmatter.slug}`}
                        title={event.node.frontmatter.name}
                        $theme={theme}
                        $colors={event.node.frontmatter.colors}>
                        {event.node.frontmatter.name}
                      </StyledLink>
                    ))
                    .slice(0, 5)}
                </>
              )}
            </Content>
          )}
        </AnimatePresence>
      </ContentContainer>
    </Dropdown>
  );
};

const DownArrow = styled(Arrow)`
  height: auto;
  margin-left: 0;
  margin-right: 0.5rem;
  min-width: 1rem;
  transform: ${({ expanded }) => (expanded ? 'none' : 'scale(-1)')};
  transition: 0.5s;
  width: 1rem;
  path {
    stroke: ${({ expanded, $theme }) => (expanded ? $theme.textColor : $theme.fg)};
    transition: 0.25s;
  }
  ${breakpoints.lg} {
    height: 0.75rem;
    min-width: 0.75rem;
    width: 0.75rem;
    path {
      stroke: ${({ expanded, $theme }) => (expanded ? $theme.textColor : $theme.fg)};
    }
  }
`;

const StyledLink = styled(Link)`
  background: transparent;
  border: none;
  color: ${({ $theme, $colors }) =>
    $theme.className === 'contrast' ? 'yellow' : $colors.secondary};
  cursor: pointer;
  display: inline-block;
  font-family: noto-sans, tahoma, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 1.25em;
  margin-bottom: 1em;
  outline: none;
  text-align: center;
  transition: 0.25s;
  width: 100%;
  &:hover,
  &:focus {
    color: ${({ $theme, $colors }) =>
      $theme.className === 'contrast' ? 'yellow' : $colors.primary};
  }
`;

const Content = styled(motion.section)`
  background: ${({ $theme }) => $theme.bgLight};
  overflow: hidden;

  padding: 2rem 1rem;
  h4 {
    color: ${({ $theme }) => $theme.textColor};
    font-size: 0.75rem;
    margin-bottom: 1em;
    text-transform: uppercase;
  }
  ${breakpoints.lg} {
    background: transparent;
    padding: 0;
    h4 {
      font-size: 0.875rem;
    }
    ${StyledLink} {
      text-align: left;
    }
  }
`;

const ContentContainer = styled.div`
  background: white;
  height: auto;
  text-align: center;
  width: 100%;
  ${breakpoints.lg} {
    background: ${({ $theme }) => $theme.bgLight};
    border-radius: 0.5rem;
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.15);
    display: ${({ expanded }) => (expanded ? 'flex' : 'none')};
    height: auto;
    left: 0;
    padding: 1.5rem;
    position: absolute;
    text-align: left;
    top: 100%;
    width: 300px;
  }
`;

const Heading = styled(motion.div)`
  align-items: center;
  background: ${({ $theme }) => $theme.bg};
  color: ${({ $theme }) => $theme.contrastTextColor};
  cursor: pointer;
  display: flex;
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.5px;
  line-height: 3rem;
  padding-top: 0.125rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  ${DownArrow} {
    path {
      stroke: white;
    }
  }
  ${breakpoints.lg} {
    background: transparent;
    border-bottom: 0.185rem solid transparent;
    color: ${({ expanded, $theme }) => (expanded ? $theme.fg : $theme.textColor)};
    line-height: 1.5rem;
    width: auto;
    ${DownArrow} {
      path {
        stroke: ${({ expanded, $theme }) => (expanded ? $theme.fg : $theme.textColor)};
      }
    }
  }
`;

const Dropdown = styled.div`
  height: auto;
  position: relative;
  width: 100%;
  ${breakpoints.lg} {
    margin-left: 1rem;
    overflow: visible;
    width: auto;
  }
`;

export default EventList;
