/* eslint-disable */

import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, LoadingSpinner, ErrorMessage } from 'components';
import { FirebaseContext, LocalContext } from 'context';
// import { TextInput } from 'components/Events/Livestream/Interaction/Polls';
import shortid from 'shortid';
import { fadeInAndOutVariants, fadeInAndOutAndAnimateHeightVariants } from 'styles';

const initialQuestionState = () => ({
  text: '',
  error: null
});

const initialAnswersState = () => [
  {
    id: shortid.generate(),
    checked: false,
    text: '',
    error: null
  },
  {
    id: shortid.generate(),
    checked: false,
    text: '',
    error: null
  }
];

const AnswerPollModal = ({ colors, isAnswerPollModalOpen, setIsAnswerPollModalOpen, poll }) => {
  const { selectedEvent } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const [pollStatus, setPollStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (true) {
      try {
        setPollStatus('submitting');

        const answer = {
          type: 'multipleChoice',
          answers: ''
        };

        await firebase.saveNewPoll({ eventName: selectedEvent.name, answer });

        // setIsAnswerPollModalOpen(false);
      } catch (error) {
        console.error(error);
        setPollStatus('error');
      }
    }
  };

  return (
    <AnimatePresence>
      {isAnswerPollModalOpen && (
        <Underlay
          data-underlay
          // onClick={(e) => {
          //   if (e.target.dataset.underlay === 'true') {
          //     setIsAnswerPollModalOpen(false);
          //   }
          // }}
          // showScrollbar={answers.length > 4}
        >
          <Modal colors={colors}>
            <Question>{poll.question.text}</Question>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div style={{ position: 'relative' }}>
                {/* <ErrorMessage
              errorMessage={question.error?.code === 'missing-question' && question.error.message}
              style={{
                color: '#F55151',
                fontSize: '0.75rem',
                position: 'absolute',
                top: '2.2rem'
              }}
              variants={fadeInAndOutAndAnimateHeightVariants()}
            /> */}
              </div>
              <Button
                colors={selectedEvent.colors}
                type="submit"
                whileHover={{
                  backgroundColor: selectedEvent.colors.tertiary
                }}
                whileTap={{
                  scale: 0.95
                }}
                style={{
                  alignSelf: 'flex-end',
                  backgroundColor: selectedEvent.colors.secondary,
                  borderRadius: '0.125rem',
                  height: '2.5rem',
                  marginTop: '1.25rem',
                  width: '11.875rem'
                }}>
                {pollStatus === 'submitting' ? (
                  <LoadingSpinner style={{ width: '1.625rem', color: '#fff' }} />
                ) : (
                  'Submit'
                )}
              </Button>
            </form>
          </Modal>
        </Underlay>
      )}
    </AnimatePresence>
  );
};

const Underlay = styled(motion.div).attrs(fadeInAndOutVariants())`
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  overflow-x: hidden;
  /* overflow-y: ${({ showScrollbar }) => (showScrollbar ? 'scroll' : 'auto')}; */
  padding: 0 1.625rem 1.625rem;
  position: absolute;
  top: 0;
  width: 100%;

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 0.75rem;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(196, 196, 196, 0.8);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 1.25rem;
  }
`;

const Modal = styled.div`
  background-color: #fff;
  margin: 3.95rem auto 0;
  max-width: 23.75rem;
  padding: 1.25rem 1.875rem;
  position: relative;

  form {
    display: flex;
    flex-direction: column;
  }
`;

const Question = styled.div``;

export default AnswerPollModal;
