import React, { useContext, useState, useEffect } from 'react';
import { Button, ErrorMessage, LoadingSpinner, Modal, LoginModal } from 'components';
import styled from 'styled-components';
import { LocalContext, FirebaseContext } from 'context';
import BaseSelect from 'react-select';
import { motion, AnimatePresence } from 'framer-motion';
import { includesSurname, hexToRGB } from 'utils';
import { fadeInAndOutVariants, fadeInAndOutAndAnimateHeightVariants } from 'styles';
import {
  FormContainer,
  FormInput,
  FormInputLabel,
  FormChecks,
  FormCheckbox,
  FormTerms
} from '../FormComponents';
import FixRequiredSelect from '../FixRequiredSelect';

const FormSelect = (props) => (
  <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />
);

const resetForm = () => ({
  name: '',
  email: '',
  connectionToEpilepsy: '',
  workshops: {
    monitoringForEpilepsy: false,
    sudep: false,
    temporalLobeEpilepsy: false,
    geneTherapy: false
  },
  termsAndConditions: false,
  referringUrl:
    typeof window !== 'undefined' &&
    JSON.parse(window.localStorage.getItem('referringUrlEIEWorkshops'))?.data
});

const connectionToEpilepsyOptions = [
  {
    value: 'A person who has epilepsy',
    label: 'A person who has epilepsy'
  },
  {
    value: 'Family/friend/carer of a person who has epilepsy',
    label: 'Family/friend/carer of a person who has epilepsy'
  },
  {
    value: 'Healthcare professional',
    label: 'Healthcare professional'
  },
  {
    value: 'Research scientist',
    label: 'Research scientist'
  },
  {
    value: 'Prefer not to say',
    label: 'Prefer not to say'
  }
];

const customDropdownMenuStyles = (colors) => ({
  container: (provided, state) => ({
    ...provided,
    alignItems: 'center',
    background: 'transparent',
    border: state.isFocused ? '0.188em solid #fff' : '0.063em solid #fff',
    display: 'flex',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '1rem',
    fontWeight: 'bold',
    height: '3.75rem',
    letterSpacing: '0.031rem',
    padding: '0'
  }),
  control: (provided) => ({
    ...provided,
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    width: '100%',
    padding: '0 0 0 1.25rem',
    cursor: 'pointer',
    height: '100%'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#fff',
    '&:hover': {
      color: '#fff'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: (provided) => ({
    ...provided,
    color: '#fff'
  }),
  menu: (provided) => ({
    ...provided,
    background: colors.primary,
    border: '0.231em solid #fff',
    borderRadius: '0',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '0.85rem',
    fontWeight: 'bold',
    left: '-0.214em',
    letterSpacing: '0.071em',
    margin: '0',
    width: 'calc(100% + 0.429em)'
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: '#fff'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.tertiary : 'transparent',
    paddingLeft: '1.25rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.secondary
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "'Noto Sans', sans-serif",
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '1rem',
    color: 'white'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#fff'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0'
  })
});

const RegistrationForm = ({ event, colors }) => {
  const { theme } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const { setANewUserHasRegisteredForAnEvent } = useContext(LocalContext);
  const [formValues, setFormValues] = useState(resetForm());
  const [errorMessage, setErrorMessage] = useState('');
  const [emailAddressAlreadyInUse, setEmailAddressAlreadyInUse] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState({
    submitting: false,
    submitted: false
  });
  const [disableSubmitButtonWhileCheckingEmailAddress, setDisableSubmitButtonWhileCheckingInputs] =
    useState(false);
  let isMounted = true;
  let debounce;
  const debounceTimeout = 750;

  useEffect(
    () => () => {
      isMounted = false;
    },
    []
  );

  useEffect(() => {
    const { name } = formValues;
    if (name) {
      setDisableSubmitButtonWhileCheckingInputs(true);
      debounce = setTimeout(() => {
        if (!includesSurname(name)) {
          setErrorMessage('Please include a surname');
        }
        setDisableSubmitButtonWhileCheckingInputs(false);
      }, debounceTimeout);
    }
    return () => clearTimeout(debounce);
  }, [formValues.name]);

  useEffect(() => {
    const { email } = formValues;
    if (email) {
      setDisableSubmitButtonWhileCheckingInputs(true);
      debounce = setTimeout(async () => {
        try {
          const result = await firebase.checkIfUserAlreadyExists({ email });
          if (!result.empty) {
            throw new Error(
              "This email address already belongs to a EIE Workshops account. Click 'Login' above if it belongs to you."
            );
          } else if (emailAddressAlreadyInUse) {
            setErrorMessage('');
            setEmailAddressAlreadyInUse(false);
          }
        } catch (error) {
          setErrorMessage(error.message);
          setEmailAddressAlreadyInUse(true);
        }
        setDisableSubmitButtonWhileCheckingInputs(false);
      }, debounceTimeout);
    }
    return () => clearTimeout(debounce);
  }, [formValues.email]);

  const handleInputChange = (e) => {
    e.persist();
    const { name, type, checked, value } = e.target;
    if (errorMessage && !emailAddressAlreadyInUse && name !== 'email') {
      setErrorMessage('');
    }
    setFormValues((currentState) => ({
      ...currentState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleWorkshopsChange = (e) => {
    e.persist();
    const { name, checked } = e.target;
    if (errorMessage && !emailAddressAlreadyInUse && name !== 'email') {
      setErrorMessage('');
    }
    setFormValues((currentState) => ({
      ...currentState,
      workshops: {
        ...currentState.workshops,
        [name]: checked
      }
    }));
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (errorMessage || submissionStatus.submitting) {
      return;
    }

    const { name, email, workshops, termsAndConditions } = formValues;

    if (!includesSurname(name)) {
      setErrorMessage('Please include a surname');
      return;
    }

    if (!email) {
      setErrorMessage('Please include an email address');
      return;
    }

    if (emailAddressAlreadyInUse) {
      setErrorMessage(
        "This email address already belongs to a EIE Workshops account. Click 'Login' above if it belongs to you."
      );
      return;
    }

    // if (extraFields?.includes('address') && !address) {
    //   setErrorMessage('Please enter your address');
    //   return;
    // }

    // if (extraFields?.includes('workplaceName') && !workplaceName) {
    //   setErrorMessage('Please enter your company or institution name');
    //   return;
    // }

    if (!(Object.entries(workshops).some((workshop) => workshop[1]))) {
      setErrorMessage("Please select at least one workshop you're interested in attending");
      return;
    }

    if (!termsAndConditions) {
      setErrorMessage('You must agree to our Terms & Conditions to register');
      return;
    }

    try {
      setSubmissionStatus({
        submitting: true,
        submitted: false
      });

      const actionCodeSettings = {
        url: `${window.location.origin}`,
        handleCodeInApp: true
      };

      const { data } = await firebase.sendSignInWithMagicLinkEmail({
        firstName: name.split(' ')[0],
        email,
        actionCodeSettings
      });

      if (data.Message === 'OK') {
        setSubmissionStatus({
          submitting: false,
          submitted: true
        });
        window.localStorage.setItem(
          'newUserEIEWorkshops',
          JSON.stringify({
            ...formValues,
            actionCodeSettings
          })
        );
        setANewUserHasRegisteredForAnEvent(true);
      } else {
        setSubmissionStatus({
          submitting: false,
          submitted: false
        });
        setErrorMessage('Error sending email. Please try again');
      }

      setFormValues(resetForm());
    } catch (error) {
      console.error(error);

      if (isMounted) {
        setErrorMessage(error.message);
      }

      setSubmissionStatus({
        submitting: false,
        submitted: false
      });
    }
  }

  return (
    <StyledFormContainer onSubmit={(e) => handleSubmit(e)} colors={colors} $theme={theme}>
      <div
        style={{
          fontSize: '1.75rem',
          fontWeight: 600,
          lineHeight: '1.75rem',
          marginBottom: '0.55rem',
          textAlign: 'center'
        }}>
        Register Here
      </div>
      <div
        style={{
          color: `#fff`,
          display: 'flex',
          fontSize: '0.875rem',
          justifyContent: 'center',
          marginBottom: '2.625em'
        }}>
        Already have an account?&nbsp;
        <Modal
          trigger={
            <span
              style={{
                fontSize: '0.875rem',
                textAlign: 'center',
                marginBottom: '1.5em',
                textDecoration: 'underline'
              }}>
              Click here!
            </span>
          }
          disableCloseOnClickOutside
          modalContent={<LoginModal colors={colors} />}
        />
      </div>
      <div
        style={{
          position: 'relative',
          display: 'grid',
          gridTemplateRows: '1fr',
          gridTemplateColumns: '1fr'
        }}>
        <div
          style={{
            gridRow: 1,
            gridColumn: 1
          }}
          variants={fadeInAndOutVariants()}
          initial="initial"
          animate="animate"
          exit="exit">
          <Fields>
            <FormInput
              id="name"
              name="name"
              onChange={handleInputChange}
              placeholder="Full Name*"
              type="text"
              value={formValues.name}
              required
            />
            <FormInput
              id="email"
              name="email"
              onChange={handleInputChange}
              placeholder="Email*"
              type="email"
              value={formValues.email}
              required
            />
            <FormSelect
              id="connectionToEpilepsy"
              name="connectionToEpilepsy"
              required
              defaultValue={formValues.connectionToEpilepsy}
              value={connectionToEpilepsyOptions.filter(
                ({ value }) => value === formValues.connectionToEpilepsy
              )}
              controlShouldRenderValue
              onChange={({ value }) => {
                setFormValues((currentStates) => ({
                  ...currentStates,
                  connectionToEpilepsy: value
                }));
              }}
              placeholder="Connection To Epilepsy*"
              styles={customDropdownMenuStyles(colors)}
              options={connectionToEpilepsyOptions}
            />
          </Fields>
          <p style={{ marginBottom: '1.25rem', fontWeight: 'bold', fontSize: '1rem', fontStyle: 'italic' }}>Workshop(s) I’m interested in attending:</p>
          {/* <FormChecks>
            <FormInputLabel hide htmlFor="monitoringForEpilepsy">
              2nd Nov - Monitoring For Epilepsy
            </FormInputLabel>
            <FormCheckbox
              $theme={theme}
              checked={formValues.workshops.monitoringForEpilepsy}
              id="monitoringForEpilepsy"
              name="monitoringForEpilepsy"
              onChange={handleWorkshopsChange}
              colors={colors}
              type="checkbox"
            />
            <FormTerms
              checked={formValues.workshops.monitoringForEpilepsy}
              colors={colors}>
              2nd Nov - Monitoring For Epilepsy
            </FormTerms>
          </FormChecks> */}
          {/* <FormChecks>
            <FormInputLabel hide htmlFor="sudep">
              9th Nov - SUDEP
            </FormInputLabel>
            <FormCheckbox
              $theme={theme}
              checked={formValues.workshops.sudep}
              id="sudep"
              name="sudep"
              onChange={handleWorkshopsChange}
              colors={colors}
              type="checkbox"
            />
            <FormTerms checked={formValues.workshops.sudep} colors={colors}>
              9th Nov - SUDEP
            </FormTerms>
          </FormChecks> */}
          {/* <FormChecks>
            <FormInputLabel hide htmlFor="temporalLobeEpilepsy">
              17th Nov - Temporal Lobe Epilepsy
            </FormInputLabel>
            <FormCheckbox
              $theme={theme}
              checked={formValues.workshops.temporalLobeEpilepsy}
              id="temporalLobeEpilepsy"
              name="temporalLobeEpilepsy"
              onChange={handleWorkshopsChange}
              colors={colors}
              type="checkbox"
            />
            <FormTerms checked={formValues.workshops.temporalLobeEpilepsy} colors={colors}>
              17th Nov - Temporal Lobe Epilepsy
            </FormTerms>
          </FormChecks> */}
          <FormChecks style={{ marginBottom: '2.5rem' }}>
            <FormInputLabel hide htmlFor="geneTherapy">
              25th Nov - Gene Therapy
            </FormInputLabel>
            <FormCheckbox
              $theme={theme}
              checked={formValues.workshops.geneTherapy}
              id="geneTherapy"
              name="geneTherapy"
              onChange={handleWorkshopsChange}
              colors={colors}
              type="checkbox"
            />
            <FormTerms checked={formValues.workshops.geneTherapy} colors={colors}>
              25th Nov - Gene Therapy
            </FormTerms>
          </FormChecks>
          <FormChecks style={{ marginBottom: '1.25rem' }}>
            <FormInputLabel hide htmlFor="termsAndConditions">
              Terms And Conditions
            </FormInputLabel>
            <FormCheckbox
              $theme={theme}
              checked={formValues.termsAndConditions}
              id="termsAndConditions"
              name="termsAndConditions"
              onChange={handleInputChange}
              required
              colors={colors}
              type="checkbox"
            />
            <FormTerms
              style={{
                fontSize: '0.75rem',
                fontWeight: 300
              }}
              dangerouslySetInnerHTML={{
                __html:
                  event?.termsAndConditions ||
                  'By supplying my contact information, I agree to the <a href="https://www.rcsi.com/dublin/privacy-policy" target="_blank" style="color: white; text-decoration: underline;">Privacy Policy</a> on the RCSI website.*'
              }}
            />
          </FormChecks>
          <ErrorMessage
            errorMessage={errorMessage}
            style={{ color: colors.tertiary }}
            variants={fadeInAndOutAndAnimateHeightVariants()}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1.25rem'
            }}>
            {submissionStatus.submitted ? (
              <div
                style={{
                  textAlign: 'center',
                  marginBottom: '0.5rem',
                  color: colors?.tertiary || '#fff',
                  width: '90%'
                }}>
                Check your inbox to verify your email address and complete your registration.
                Can&apos;t find our email? Be sure to check your junk!
              </div>
            ) : (
              <Button
                type="submit"
                width="7.5rem"
                whileTap={{ scale: 0.95 }}
                disabled={emailAddressAlreadyInUse || disableSubmitButtonWhileCheckingEmailAddress}
                style={{
                  alignItems: 'center',
                  alignSelf: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative'
                }}>
                {
                  <AnimatePresence>
                    {submissionStatus.submitting && (
                      <LoadingSpinner
                        style={{ width: '2rem', color: '#fff', position: 'absolute' }}
                      />
                    )}
                  </AnimatePresence>
                }
                {
                  <AnimatePresence>
                    {!submissionStatus.submitting && (
                      <motion.span
                        variants={fadeInAndOutVariants()}
                        initial="initial"
                        animate="animate"
                        exit="exit">
                        Submit
                      </motion.span>
                    )}
                  </AnimatePresence>
                }
              </Button>
            )}
          </div>
        </div>
      </div>
    </StyledFormContainer>
  );
};

const StyledFormContainer = styled(FormContainer)`
  background: ${({ colors }) => colors.primary};

  /* @media screen and (min-width: 1150px) {
    background: ${({ colors }) => colors.primary};
  } */

  input {
    margin-bottom: 0;
  }

  button {
    background: ${({ colors }) => colors.tertiary};
  }
`;

const Fields = styled.div`
  display: grid;
  grid-row-gap: 1.625rem;
  grid-template-columns: 1fr;
  margin-bottom: 1.625rem;
`;

export default RegistrationForm;
