import React, { useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { LocalContext } from 'context';
import { WonkyAvocado, BrokenCircle, CornerBubble } from 'assets/svgs';
import { breakpoints } from 'styles';

const EventHeaderBackground = ({ colors }) => {
  const { theme } = useContext(LocalContext);

  return (
    <Background $theme={theme} colors={colors}>
      <WonkyAvocado className="avocado" />
      <BrokenCircle className="broken-circle" colors={colors} />
      <CornerBubble className="corner-bubble" colors={colors} />
      <Circles colors={colors}>
        <Circle />
        <Circle />
        <Circle />
      </Circles>
    </Background>
  );
};

const rotate = keyframes`
  from{
    transform: rotate(-30deg);
  }
  to{
    transform: rotate(120deg);
  }
`;

const sway = keyframes`
  0%{
    transform: rotate(0deg) translateX(10px) rotate(0deg);
  }
  to{
    transform: rotate(360deg) translateX(10px) rotate(-360deg);
  }
`;

const swayAlt = keyframes`
  0%{
    transform: rotate(0deg) translateX(15px) rotate(0deg);
  }
  to{
    transform: rotate(-360deg) translateX(15px) rotate(360deg);
  }
`;

const Circle = styled.div`
  height: 10rem;
  position: absolute;
  width: 10rem;
`;

const Circles = styled.div`
  bottom: 0;
  display: none;
  height: 100%;
  position: absolute;
  right: 0;
  width: 50%;

  @media (min-width: 768px) {
    display: block;
  }

  ${Circle} {
    border-radius: 50%;
    &:nth-child(1) {
      animation: ${swayAlt} 8s infinite alternate;
      background: ${({ colors }) => colors.primary};
      height: 4rem;
      right: 60%;
      top: 15%;
      transform: rotate(45deg) translateX(5px) rotate(-45deg);
      width: 4rem;
    }
    &:nth-child(2) {
      animation: ${sway} 10s infinite alternate;
      background: ${({ colors }) => colors.tertiary};
      bottom: 2rem;
      height: 15rem;
      opacity: 0.2;
      right: 55%;
      transform: rotate(45deg) translateX(10px) rotate(-45deg);
      width: 15rem;
    }
    &:nth-child(3) {
      background: ${({ colors }) => colors.secondary};
      bottom: -3rem;
      height: 10rem;
      right: 55%;
      width: 10rem;
    }
  }
`;

const Background = styled.div`
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;

  svg {
    position: absolute;

    &.avocado {
      top: -50%;
      right: -40%;
      width: 100%;
    }

    &.broken-circle {
      path {
        fill: ${({ colors }) => colors.primary};
      }
      top: 12%;
      right: 12%;
      width: 10rem;
      transform: rotate(30deg);
      animation: ${rotate} 10s infinite;
      animation-direction: alternate;
    }

    &.corner-bubble {
      bottom: 0;
      left: 0;
      width: 7.5rem;
    }
  }

  ${breakpoints.sm} {
    svg {
      &.corner-bubble {
        bottom: 0;
        left: 0;
        width: 9rem;
      }
    }
  }
`;

export default EventHeaderBackground;
