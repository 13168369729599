import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { LocalContext } from 'context';

const ModalContent = (props) => {
  const { theme } = useContext(LocalContext);
  return ReactDOM.createPortal(
    <ModalCover
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      tag="aside"
      role="dialog"
      tabIndex="-1"
      aria-modal="true"
      className="modal-cover"
      onKeyDown={props.onKeyDown}>
      <ModalArea
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={props.onClickOutside}>
        <ModalBody ref={props.modalRef} onClick={(e) => e.stopPropagation()}>
          {!props.hideCloseButton && (
            <ModalClose
              ref={props.buttonRef}
              aria-label="Close Modal"
              aria-labelledby="close-modal"
              onClick={props.closeModal}
              $theme={theme}>
              <HideVisual id="close-modal">Close</HideVisual>
              <ModalCloseIcon $theme={theme} viewBox="0 0 40 40">
                <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
              </ModalCloseIcon>
            </ModalClose>
          )}
          {props.content}
        </ModalBody>
      </ModalArea>
    </ModalCover>,
    document.body
  );
};

const ModalCloseIcon = styled.svg`
  height: 3rem;
  stroke-linecap: round;
  stroke-width: 3;
  width: 3rem;
`;

const ModalClose = styled.button`
  align-items: center;
  background: ${({ $theme }) => $theme.bg};
  border: 0;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  display: flex;
  height: 2rem;
  justify-content: center;
  line-height: 1;
  padding: 0.5em;
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  transition: 0.2s;
  width: 2rem;
  z-index: 20;
  &:hover {
    transform: scale3d(1.075, 1.075, 1.075);
    transition: transform 200ms ease-in-out;
  }
  svg {
    path {
      fill: ${({ $theme }) => $theme.bgLight};
      stroke: ${({ $theme }) => $theme.bgLight};
    }
  }
`;

const HideVisual = styled.span`
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
`;

const ModalBody = styled.div`
  height: auto;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  width: auto;
  form {
    margin-bottom: 20px;
  }
`;

const ModalArea = styled(motion.aside)`
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  max-width: 100%;
  overflow-y: auto;
  overscroll-behavior: none;
  padding: 0 1rem;
  width: 100%;
`;

const ModalCover = styled.div`
  background-color: rgba(255, 255, 255, 0.875);
  box-sizing: border-box;
  height: 100%;
  left: 0;
  max-width: 100vw;
  padding: 1rem 0.25rem;
  position: fixed;
  top: 0;
  transform: translateZ(0);
  width: 100%;
  z-index: 10;
`;

export default ModalContent;
