import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import {
  Chat,
  // Polls,
  ParticipantModal,
  Participants,
  QAndA
} from 'components';
import { FirebaseContext } from 'context';
import { AnimatePresence } from 'framer-motion';
import { breakpoints } from 'styles';

const Interaction = ({ colors, eventName, questionCurrentlyBeingAnsweredLive }) => {
  const { user } = useContext(FirebaseContext);
  const [activeTab, setActiveTab] = useState('Chat');
  const [showParticipantModal, setShowParticipantModal] = useState(false);

  useEffect(() => {
    if (showParticipantModal) {
      setShowParticipantModal(false);
    }
  }, [activeTab]);

  const handleParticipantAvatarOrNameClick = (participant) => {
    if (participant) {
      setShowParticipantModal({
        avatarUrl: participant.avatarUrl,
        name: participant.name,
        profession: participant.profession,
        workplaceName: participant.workplaceName
      });
    } else {
      setShowParticipantModal(false);
    }
  };

  return (
    <Wrapper>
      <Tabs>
        <Tab isAdmin={user && user.isAdmin} isActive={activeTab === 'Chat'} onClick={() => setActiveTab('Chat')} colors={colors}>
          Chat
        </Tab>
        {/* <Tab isActive={activeTab === 'Q&A'} onClick={() => setActiveTab('Q&A')} colors={colors}>
          Q & A
        </Tab> */}
        {/* <Tab isActive={activeTab === 'Polls'} onClick={() => setActiveTab('Polls')} colors={colors}>
          Polls
        </Tab> */}
        {user && user.isAdmin && (
          <Tab
            isAdmin={user && user.isAdmin}
            isActive={activeTab === 'Participants'}
            onClick={() => setActiveTab('Participants')}
            colors={colors}>
            Participants
          </Tab>
        )}
      </Tabs>
      <AnimatePresence>
        {activeTab === 'Chat' && (
          <Chat
            colors={colors}
            eventName={eventName}
            handleParticipantAvatarOrNameClick={handleParticipantAvatarOrNameClick}
          />
        )}
      </AnimatePresence>
      {/* <AnimatePresence>
        {activeTab === 'Q&A' && (
          <QAndA
            colors={colors}
            eventName={eventName}
            questionCurrentlyBeingAnsweredLive={questionCurrentlyBeingAnsweredLive}
          />
        )}
      </AnimatePresence> */}
      {/* <AnimatePresence>{activeTab === 'Polls' && <Polls colors={colors} />}</AnimatePresence> */}
      {user && user.isAdmin && (
        <AnimatePresence>
          {activeTab === 'Participants' && (
            <Participants
              colors={colors}
              eventName={eventName}
              showParticipantModal={showParticipantModal}
              handleParticipantAvatarOrNameClick={handleParticipantAvatarOrNameClick}
            />
          )}
        </AnimatePresence>
      )}
      <AnimatePresence>
        {showParticipantModal && (
          <ParticipantModal
            colors={colors}
            handleParticipantAvatarOrNameClick={handleParticipantAvatarOrNameClick}
            showParticipantModal={showParticipantModal}
          />
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* background-color: transparent; */
  background-color: #efedf5;
  display: flex;
  flex-direction: column;
  height: 400px;
  position: relative;
  width: 100%;

  > div:not(:first-child) {
    border: 1px solid #bdbdbd;
    border-top: none;
    height: calc(100% - 2.875rem);
    margin-top: 2.875rem;
    position: absolute;
    width: 100%;
  }

  ${breakpoints.lg} {
    height: 100%;
  }
`;

const Tabs = styled.div`
  align-items: flex-end;
  background-color: transparent;
  display: flex;
  height: 2.875rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Tab = styled.div`
  align-items: center;
  border: 0.071em solid #bdbdbd;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  justify-content: center;
  padding: 0 1.125em;
  text-transform: uppercase;
  transition: all 200ms ease-in-out;

  ${({ isActive, isAdmin }) =>
    isActive
      ? css`
          background-color: #fff;
          border-top-color: ${({ colors }) => colors.tertiary};
          border-top-width: 0.357em;
          color: #3c3c3c;
          height: 3.286em;
          width: ${isAdmin ? '51%' : '100%'};
        `
      : css`
          background-color: #e5e5e5;
          border-top-color: #bdbdbd;
          border-top-width: 0.071em;
          color: #bdbdbd;
          height: 2.929em;
          width: ${isAdmin ? '49%' : '100%'};

          &:first-child {
            border-right: none;
          }

          &:last-child {
            border-left: none;
          }
        `};
`;

export default Interaction;
