import * as React from 'react';

function SvgBlob1(props) {
  return (
    <svg viewBox="0 0 572 693" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity={0.6}
        d="M349.337 535.233c-64.202 36.685-94.532 136.058-160.654 118.095-66.121-17.964-125.337-61.211-162.055-125.299-36.718-64.088 39.254-37.408 57.218-103.503 17.964-66.095-21.988-224.954 42.214-261.639 64.202-36.686 107.207-152.484 173.317-134.428 66.11 18.056 155.268 169.677 191.974 233.858 36.706 64.181 43.955 137.077 25.991 203.172s-103.896 33.046-168.005 69.744z"
        fill="url(#blob1_svg__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="blob1_svg__paint0_linear"
          x1={13.245}
          y1={281.057}
          x2={511.857}
          y2={410.337}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDD8DA" />
          <stop offset={0.455} stopColor="#FEA6C1" stopOpacity={0.545} />
          <stop offset={0.805} stopColor="#FF87B2" stopOpacity={0.195} />
          <stop offset={1} stopColor="#FF7BAC" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgBlob1;
