import React from 'react';
import { Speaker } from 'components';
import { CardDeck, defaultColors } from 'styles';
import { graphql, useStaticQuery } from 'gatsby';

const Speakers = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          sort: { order: [ASC], fields: [frontmatter___speakerOrder] }
          filter: { fileAbsolutePath: { regex: "/(speakers)/" }, frontmatter: {} }
        ) {
          nodes {
            id
            frontmatter {
              speakerOrder
              name
              position
              fbLink
              twLink
              linkedinLink
              emailLink
              summary
              image {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    formats: [AUTO, WEBP, AVIF]
                    width: 300
                    height: 300
                    placeholder: TRACED_SVG
                  )
                }
              }
            }
          }
        }
      }
    `
  );
  const speakers = data.allMarkdownRemark.nodes;
  return (
    <CardDeck>
      {speakers.map((speaker) => (
        <Speaker
          key={speaker.id}
          data={speaker.frontmatter}
          colors={defaultColors}
          buttonText="Read Overview"
          popupType="speaker"
          speakers={speakers}
        />
      ))}
    </CardDeck>
  );
};

export default Speakers;
