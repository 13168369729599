import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, CreateOrEditPollModal, ActiveAndClosedPolls } from 'components';
import { FirebaseContext, LocalContext } from 'context';
import { AnimatePresence } from 'framer-motion';
import { hexToRGB } from 'utils';
import { Plus } from 'assets/svgs';
import { fadeInAndOutVariants } from 'styles';

const ControlPanel = () => {
  const { selectedEvent } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const [editPoll, setEditPoll] = useState(null);
  const [isCreateOrEditPollModalOpen, setIsCreateOrEditPollModalOpen] = useState(false);
  const [activePolls, setActivePolls] = useState([]);
  const [closedPolls, setClosedPolls] = useState([]);
  const [allPolls, setAllPolls] = useState([]);
  const [selectedPoll, setSelectedPoll] = useState(null);
  const [currentlyShownPollPid, setCurrentlyShownPollPid] = useState(null);

  useEffect(() => {
    let unsubscribeFromPolls;

    if (firebase) {
      unsubscribeFromPolls = firebase.subscribeToPolls({
        eventName: selectedEvent.name,
        onSnapshot: (snapshot) => {
          if (snapshot.exists()) {
            const _activePolls = [];
            const _closedPolls = [];
            const _allPolls = [];

            snapshot.forEach((child) => {
              _allPolls.push({
                pid: child.key,
                ...child.val()
              });
            });

            const currentlyShownPoll = _allPolls.find((poll) => poll.showPoll);

            if (currentlyShownPoll) {
              setCurrentlyShownPollPid(currentlyShownPoll.pid);
            }

            _allPolls.forEach((poll) => {
              if (poll.isActive) {
                _activePolls.push(poll);
              } else {
                _closedPolls.push(poll);
              }
            });

            setActivePolls(_activePolls);
            setClosedPolls(_closedPolls);
            setAllPolls(_allPolls);
          } else if (!snapshot.exists()) {
            setActivePolls([]);
            setClosedPolls([]);
            setAllPolls([]);
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromPolls) {
        unsubscribeFromPolls.off();
      }
    };
  }, [firebase]);

  useEffect(() => {
    if (allPolls.length) {
      allPolls.forEach((poll) => {
        if (selectedPoll?.pid === poll.pid) {
          // Whenever the 'subscribeToPolls' snapshot is fired we loop through all the polls and
          // rehydrate the currently selected poll (if there is one) with any new properties
          // e.g. if it's been changed from an active to a closed poll.
          setSelectedPoll(poll);
        }
      });
    }
  }, [allPolls]);

  const handlePoll = (action) => {
    const selectedPollData = {
      eventName: selectedEvent.name,
      pid: selectedPoll.pid
    };
    switch (action) {
      case 'showPoll':
        firebase.showSelectedPoll({ selectedEvent, selectedPollData, currentlyShownPollPid });
        break;
      case 'endPoll':
        firebase.endSelectedPoll({ selectedEvent, selectedPollData });
        setCurrentlyShownPollPid(null);
        break;
      case 'showResults':
        // firebase.showResultsOfSelectedPoll({ selectedPollData });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <p>Moderator&apos;s Control Panel</p>
      <Button
        onClick={() => setIsCreateOrEditPollModalOpen(true)}
        width="190px"
        style={{
          backgroundColor: selectedEvent.colors.secondary,
          borderRadius: '2px',
          margin: '0 auto'
        }}
        whileHover={{
          backgroundColor: selectedEvent.colors.tertiary
        }}
        whileTap={{
          scale: 0.95
        }}>
        <Plus width="10px" />
        &nbsp;&nbsp;Create Poll
      </Button>
      <ActiveAndClosedPolls
        activePolls={activePolls}
        closedPolls={closedPolls}
        selectedPoll={selectedPoll}
        setSelectedPoll={setSelectedPoll}
        currentlyShownPollPid={currentlyShownPollPid}
        setCurrentlyShownPollPid={setCurrentlyShownPollPid}
        setIsCreateOrEditPollModalOpen={setIsCreateOrEditPollModalOpen}
        setEditPoll={setEditPoll}
      />
      <AnimatePresence>
        {(activePolls.length || closedPolls.length) && (
          <Buttons colors={selectedEvent.colors}>
            <AnimatePresence>
              {activePolls.length && (!selectedPoll || (selectedPoll && selectedPoll.isActive)) && (
                <Button
                  onClick={() =>
                    selectedPoll && selectedPoll.pid === currentlyShownPollPid
                      ? handlePoll('endPoll')
                      : handlePoll('showPoll')
                  }
                  style={{
                    color:
                      selectedPoll &&
                      (selectedPoll.pid === currentlyShownPollPid ||
                        selectedPoll.pid !== currentlyShownPollPid)
                        ? '#fff'
                        : '#c4c4c4',
                    backgroundColor:
                      selectedPoll && selectedPoll.pid === currentlyShownPollPid
                        ? selectedEvent.colors.tertiary
                        : selectedPoll && selectedPoll.pid !== currentlyShownPollPid
                        ? selectedEvent.colors.secondary
                        : '#e5e5e5',
                    gridColumn: '1/2'
                  }}
                  whileHover={{
                    boxShadow:
                      selectedPoll &&
                      `0 0 0.5rem 0.025rem ${hexToRGB({
                        colors:
                          selectedPoll.pid === currentlyShownPollPid
                            ? selectedEvent.colors.tertiary
                            : selectedEvent.colors.secondary,
                        alpha: 0.25
                      })}`
                  }}
                  whileTap={{
                    scale: selectedPoll && 0.95
                  }}
                  disabled={!selectedPoll}
                  variants={fadeInAndOutVariants()}
                  initial="initial"
                  animate="animate"
                  exit="exit">
                  {selectedPoll && selectedPoll.pid === currentlyShownPollPid
                    ? 'End Poll'
                    : 'Show Poll'}
                </Button>
              )}
            </AnimatePresence>
            <Button
              onClick={() => handlePoll('showResults')}
              style={{
                color: selectedPoll && !selectedPoll.isActive ? '#fff' : '#c4c4c4',
                backgroundColor:
                  selectedPoll && !selectedPoll.isActive
                    ? selectedEvent.colors.tertiary
                    : '#e5e5e5',
                gridColumn: '2'
              }}
              whileHover={{
                boxShadow:
                  selectedPoll &&
                  !selectedPoll.isActive &&
                  `0 0 0.5rem 0.025rem ${hexToRGB({
                    colors: selectedEvent.colors.tertiary,
                    alpha: 0.25
                  })}`
              }}
              disabled={!selectedPoll || selectedPoll.isActive}>
              Show Results
            </Button>
          </Buttons>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isCreateOrEditPollModalOpen && (
          <CreateOrEditPollModal
            colors={selectedEvent.colors}
            setIsCreateOrEditPollModalOpen={setIsCreateOrEditPollModalOpen}
            editPoll={editPoll}
            setEditPoll={setEditPoll}
          />
        )}
      </AnimatePresence>
    </>
  );
};

const Buttons = styled.section`
  align-items: center;
  border-top: 0.063rem solid #bdbdbd;
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 190px));
  height: 5rem;
  justify-content: space-between;

  button {
    border-radius: 0.125rem;
    height: 2.5rem;
    max-width: 11.875rem;
  }
`;

export default ControlPanel;
