import React, { useState, useContext, useEffect } from 'react';
import { Button, ErrorMessage, LoadingSpinner, Notification } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import styled, { css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import Dropzone from 'react-dropzone';
import firebaseApp from 'firebase/app';
import { defaultColors, fadeInAndOutAndAnimateHeightVariants } from 'styles';
import { hexToRGB } from 'utils';
import { FormContainer, FormLabel, FormInput, FormInputLabel, TextArea } from '../FormComponents';

const UploadForm = ({ eventName }) => {
  const { theme, selectedEvent } = useContext(LocalContext);
  const { firebase, user } = useContext(FirebaseContext);
  const [formValues, setFormValues] = useState({
    author: '',
    title: '',
    summary: '',
    poster: {
      fileName: '',
      downloadURL: '',
      previewImg: ''
    },
    timestamp: ''
  });
  const [isDropzoneAreaBackgroundOrange, setIsDropzoneAreaBackgroundOrange] = useState(false);
  const [posterUploadProgress, setPosterUploadProgress] = useState({
    uploading: false,
    uploaded: false,
    converting: false,
    converted: false,
    percentage: 0
  });
  const [submittingProject, setProjectSubmissionStatus] = useState({
    submitting: false,
    submitted: false
  });
  const [notification, setNotification] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const resetForm = () => {
    setFormValues({
      author: '',
      title: '',
      summary: '',
      poster: {
        fileName: '',
        downloadURL: '',
        previewImg: ''
      },
      timestamp: ''
    });
    setPosterUploadProgress({
      uploading: false,
      uploaded: false,
      converting: false,
      converted: false,
      percentage: 0
    });
    setProjectSubmissionStatus({
      submitting: false,
      submitted: false
    });
  };

  useEffect(() => () => resetForm(), []);

  const handleTextInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((currentStates) => ({
      ...currentStates,
      [name]: value
    }));
  };

  const uploadPoster = (files) => {
    setIsDropzoneAreaBackgroundOrange(false);

    if (posterUploadProgress.uploaded) {
      setPosterUploadProgress({
        uploading: false,
        uploaded: false,
        converting: false,
        converted: false,
        percentage: 0
      });
    }

    if (files.length > 1) {
      setErrorMessage('Only one file allowed');
      return;
    }

    if (files[0].type !== 'application/pdf') {
      setErrorMessage('Only PDF files allowed');
      return;
    }

    const date = new Date();
    const timestamp = date.getTime();

    const fileName = `poster_${timestamp}_${user.uid}.pdf`;

    const storageRef = firebaseApp.app().storage('gs://panacea-posters').ref(fileName);

    const uploadTask = storageRef.put(files[0]);

    uploadTask.on(
      'state_changed',
      function progress(snapshot) {
        setPosterUploadProgress({
          uploading: true,
          uploaded: false,
          converting: false,
          converted: false,
          percentage: (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        });
      },
      function error(err) {
        console.error(err);
        setErrorMessage(err.message);
        setFormValues((currentStates) => ({
          ...currentStates,
          poster: {
            fileName: '',
            downloadURL: '',
            previewImg: ''
          }
        }));
        setPosterUploadProgress({
          uploading: false,
          uploaded: false,
          converting: false,
          converted: false,
          percentage: 0
        });
      },
      function complete() {
        uploadTask.then((snapshot) => {
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            setPosterUploadProgress({
              uploading: false,
              uploaded: true,
              converting: true,
              converted: false,
              percentage: (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            });
            firebase
              .convertPDFtoPNG({ fileName })
              .then(({ data: previewImg }) => {
                setFormValues((currentStates) => ({
                  ...currentStates,
                  poster: {
                    fileName: files[0].name,
                    downloadURL,
                    previewImg
                  },
                  timestamp
                }));
                setPosterUploadProgress({
                  uploading: false,
                  uploaded: true,
                  converting: false,
                  converted: true,
                  percentage: 0
                });
              })
              .catch(console.error);
          });
        });
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProjectSubmissionStatus({
      submitting: true,
      submitted: false
    });

    firebase
      .uploadProject({
        ...formValues,
        eventName
      })
      .then(() => {
        setNotification(
          'Your project has been submitted<br />and will appear on the site shortly.'
        );
        setProjectSubmissionStatus({
          submitting: false,
          submitted: true
        });
      });
  };

  return (
    <FormContainer onSubmit={handleSubmit} $theme={theme}>
      <FormLabel>Upload Project</FormLabel>
      <FormInputLabel hide htmlFor="title">
        Project Title
      </FormInputLabel>
      <FormInput
        id="title"
        name="title"
        type="text"
        placeholder="Enter Project Title"
        onChange={handleTextInputChange}
        value={formValues.title}
        required
      />
      <FormInputLabel hide htmlFor="author">
        Project Author
      </FormInputLabel>
      <FormInput
        id="author"
        name="author"
        type="text"
        placeholder="Enter Project Author(s)"
        onChange={handleTextInputChange}
        value={formValues.author}
        required
      />
      <FormInputLabel hide htmlFor="summary">
        Summary
      </FormInputLabel>
      <TextArea
        $theme={theme}
        id="summary"
        name="summary"
        type="text"
        placeholder="Enter Short Summary"
        onChange={handleTextInputChange}
        value={formValues.summary}
        required
      />
      <Dropzone
        onDrop={uploadPoster}
        onDragEnter={() => setIsDropzoneAreaBackgroundOrange(true)}
        onDragLeave={() => setIsDropzoneAreaBackgroundOrange(false)}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <DropzoneArea
              colors={selectedEvent ? selectedEvent.colors : defaultColors}
              {...getRootProps({
                onMouseEnter: () => setIsDropzoneAreaBackgroundOrange(true),
                onMouseOut: () => setIsDropzoneAreaBackgroundOrange(false)
              })}
              isDropzoneAreaBackgroundOrange={isDropzoneAreaBackgroundOrange}>
              <input {...getInputProps()} required={!formValues.poster.fileName} name="files" />
              <p
                style={{
                  pointerEvents: 'none',
                  textAlign: 'center',
                  lineHeight: '1.25em',
                  margin: '0 0.75rem'
                }}>
                {posterUploadProgress.uploading ? (
                  <Ellipses>Uploading File</Ellipses>
                ) : posterUploadProgress.converting ? (
                  <Ellipses>Converting File</Ellipses>
                ) : posterUploadProgress.uploaded && posterUploadProgress.converted ? (
                  <strong>{formValues.poster.fileName}</strong>
                ) : (
                  <>
                    <strong>Drag & drop</strong> your poster here or <u>click</u> to browse files
                    <br />
                    <em>(Only PDF files allowed)</em>
                  </>
                )}
              </p>
            </DropzoneArea>
          </section>
        )}
      </Dropzone>
      <AnimatePresence>
        {(posterUploadProgress.uploading || posterUploadProgress.converting) && (
          <ProgressBar
            colors={selectedEvent ? selectedEvent.colors : defaultColors}
            value={posterUploadProgress.percentage}
            max="100"
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: 1,
              height: '1rem',
              transition: {
                ease: 'easeInOut',
                duration: 0.02,
                opacity: {
                  delay: 0.01
                }
              }
            }}
            exit={{
              opacity: 0,
              height: 0,
              transition: {
                ease: 'easeInOut',
                duration: 0.02,
                height: {
                  delay: 0.01
                }
              }
            }}
          />
        )}
      </AnimatePresence>
      <Notification notification={notification} animationDuration="0.4" />
      <ErrorMessage errorMessage={errorMessage} variants={fadeInAndOutAndAnimateHeightVariants()} />
      <Button
        type="submit"
        disabled={posterUploadProgress.uploading || posterUploadProgress.converting}
        onClick={(e) => {
          if (
            formValues.title &&
            formValues.author &&
            formValues.summary &&
            !formValues.poster.fileName
          ) {
            setErrorMessage('You must upload a poster');
          }
          if (submittingProject.submitted) {
            e.preventDefault();
            resetForm();
            setNotification('');
          }
        }}
        width="10rem"
        whileTap={{
          scale: 0.9
        }}
        style={{ margin: '0.625rem auto 0' }}>
        {submittingProject.submitting ? (
          <LoadingSpinner style={{ width: '2.25rem' }} />
        ) : submittingProject.submitted ? (
          'Thank You!'
        ) : (
          'Submit'
        )}
      </Button>
    </FormContainer>
  );
};

const Ellipses = styled.strong`
  &:after {
    animation: dots 1s steps(5, end) infinite;
    content: ' .';
    margin-left: -0.1rem;
  }

  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.35rem 0 0 rgba(0, 0, 0, 0), 0.7rem 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: #fff;
      text-shadow: 0.35rem 0 0 rgba(0, 0, 0, 0), 0.7rem 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow: 0.35rem 0 0 #fff, 0.7rem 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow: 0.35rem 0 0 #fff, 0.7rem 0 0 #fff;
    }
  }
`;

const ProgressBar = styled(motion.progress)`
  appearance: none;
  width: 100%;

  &::-webkit-progress-bar {
    border: 0.063rem solid #fff;
    background-color: rgba(255, 255, 255, 0.3);
  }

  &::-webkit-progress-value {
    background-color: ${({ colors }) => colors.tertiary};
  }
`;

const DropzoneArea = styled.div`
  align-items: center;
  border-radius: 0.125em;
  cursor: pointer;
  display: flex;
  height: 6rem;
  justify-content: center;
  margin-bottom: 0.625rem;
  outline: none;
  transition: background-color 150ms ease-in-out, border 150ms ease-in-out;

  ${({ isDropzoneAreaBackgroundOrange, colors }) =>
    isDropzoneAreaBackgroundOrange
      ? css`
          background-color: ${hexToRGB({ color: colors.tertiary, alpha: 0.3 })};
          border: 0.063rem solid ${colors.tertiary};
        `
      : css`
          background-color: rgba(255, 255, 255, 0.3);
          border: 0.063rem solid #fff;
        `};
`;

export default UploadForm;
