import React, { useState, useEffect, useContext } from 'react';
import {
  Modal,
  NewUserEmailVerification,
  LoggingExistingUserIn,
  RecoverEmail,
  ActionCodeError,
  LoginModal,
  Unsubscribe
} from 'components';
import { FirebaseContext } from 'context';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { clearQueryParams } from 'utils';
import { defaultColors } from 'styles';

export default () => {
  const [userEmail, setUserEmail] = useState('');
  const [emailRecovered, setEmailRecovered] = useState(false);
  const [newUserEmailVerified, setNewUserEmailVerified] = useState(false);
  const [showNewUserEmailVerificationModal, setShowNewUserEmailVerificationModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showLoggingExistingUserInModal, setShowLoggingExistingUserInModal] = useState(false);
  const [showRecoverEmailModal, setShowRecoverEmailModal] = useState(false);
  const [actionCodeError, setActionCodeError] = useState({ mode: null, message: null });
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const { loading, firebase } = useContext(FirebaseContext);
  const { mode, oobCode: actionCode, unsubscribe } = queryString.parse(useLocation().search);
  const colors = defaultColors;

  useEffect(() => {
    if (unsubscribe) {
      setShowUnsubscribeModal(true);
    }
  }, [unsubscribe]);

  useEffect(() => {
    if (!loading && firebase) {
      const existingUserLocalStorageData = JSON.parse(
        window.localStorage.getItem('existingUserEIEWorkshops')
      );
      const newUserLocalStorageData = JSON.parse(
        window.localStorage.getItem('newUserEIEWorkshops')
      );
      const referringUrl = JSON.parse(
        window.localStorage.getItem('referringUrlEIEWorkshops')
      )?.data;
      switch (mode) {
        case 'signIn':
          if (existingUserLocalStorageData?.email || newUserLocalStorageData?.email) {
            firebase.auth
              .signInWithEmailLink(
                existingUserLocalStorageData?.email || newUserLocalStorageData?.email,
                window.location.href
              )
              .then((result) => {
                const { isNewUser } = result.additionalUserInfo;
                if (isNewUser) {
                  if (newUserLocalStorageData) {
                    setShowNewUserEmailVerificationModal(true);
                  }
                  firebase
                    .createUserInDatabase({
                      name: newUserLocalStorageData.name,
                      email: newUserLocalStorageData.email,
                      connectionToEpilepsy: newUserLocalStorageData.connectionToEpilepsy,
                      workshops: newUserLocalStorageData.workshops,
                      referringUrl,
                      uid: result.user.uid
                    })
                    .then(() => {
                      firebase?.auth?.currentUser?.reload().then(() => {
                        // TODO: Send Welcome To Panacea Email.
                        setNewUserEmailVerified(true);
                        window.localStorage.removeItem('newUserEIEWorkshops');
                        window.localStorage.removeItem('referringUrlEIEWorkshops');
                        clearQueryParams();
                      });
                    })
                    .catch((error) => {
                      console.error(error);
                      // TODO: Check if user was created in Auth and delete them if so.
                      setShowNewUserEmailVerificationModal(false);
                      clearQueryParams();
                    });
                } else if (!isNewUser) {
                  if (existingUserLocalStorageData) {
                    setShowLoggingExistingUserInModal(true);
                  }
                  window.localStorage.removeItem('existingUserEIEWorkshops');
                  if (newUserLocalStorageData) {
                    window.localStorage.removeItem('newUserEIEWorkshops');
                    window.localStorage.removeItem('referringUrlEIEWorkshops');
                  }
                  clearQueryParams();
                }
              })
              .catch((error) => {
                console.error(error.message);

                if (showNewUserEmailVerificationModal) {
                  setShowNewUserEmailVerificationModal(false);
                }

                if (showLoggingExistingUserInModal) {
                  setShowLoggingExistingUserInModal(false);
                }

                if (existingUserLocalStorageData) {
                  window.localStorage.removeItem('existingUserEIEWorkshops');
                }

                if (newUserLocalStorageData) {
                  window.localStorage.removeItem('newUserEIEWorkshops');
                  window.localStorage.removeItem('referringUrlEIEWorkshops');
                }

                clearQueryParams();

                switch (error.code) {
                  case 'auth/invalid-action-code':
                    setActionCodeError({
                      mode,
                      message: 'Invalid or expired link.'
                    });
                    break;
                  default:
                    setActionCodeError({
                      mode,
                      message: error.message
                    });
                    break;
                }
              });
          } else if (!firebase?.auth?.currentUser) {
            // User registered or tried to log in on another device/browser
            setShowLoginModal(true);
          } else if (firebase?.auth?.currentUser) {
            clearQueryParams();
          }
          break;
        case 'recoverEmail':
          setShowRecoverEmailModal(true);
          firebase
            .checkActionCode(actionCode)
            .then(({ data }) => {
              const recoveredEmail = data.email;
              return firebase
                .applyActionCode(actionCode)
                .then(() => firebase.updateEmailInDatabase(recoveredEmail))
                .then(() => {
                  setEmailRecovered(true);
                  setUserEmail(recoveredEmail);
                });
            })
            .catch((error) => {
              console.error(error);
              setShowRecoverEmailModal(false);
              switch (error.code) {
                case 'auth/invalid-action-code':
                  setActionCodeError({
                    mode,
                    message: 'Invalid or expired link.<br />Please try again.'
                  });
                  break;
                default:
                  setActionCodeError({
                    mode,
                    message: error.message
                  });
                  break;
              }
            });
          break;
        default:
          break;
      }
    }
  }, [loading, firebase]);

  return (
    <>
      <Modal
        hideCloseButton
        openWithoutTrigger={showUnsubscribeModal}
        modalContent={<Unsubscribe />}
      />
      <Modal
        hideCloseButton
        disableCloseOnClickOutside
        openWithoutTrigger={showNewUserEmailVerificationModal}
        modalContent={
          <NewUserEmailVerification
            setShowNewUserEmailVerificationModal={setShowNewUserEmailVerificationModal}
            newUserEmailVerified={newUserEmailVerified}
          />
        }
      />
      <Modal
        hideCloseButton
        disableCloseOnClickOutside
        openWithoutTrigger={showLoggingExistingUserInModal}
        modalContent={
          <LoggingExistingUserIn
            setShowLoggingExistingUserInModal={setShowLoggingExistingUserInModal}
          />
        }
      />
      <Modal
        hideCloseButton
        // disableCloseOnClickOutside
        openWithoutTrigger={showLoginModal}
        redirectIfNotAuthorised
        modalContent={
          <LoginModal
            colors={colors}
            setShowLoginModal={setShowLoginModal}
            userRegisteredUsingAnotherDeviceOrBrowser
          />
        }
      />
      <Modal
        hideCloseButton
        disableCloseOnClickOutside
        openWithoutTrigger={showRecoverEmailModal}
        modalContent={
          <RecoverEmail
            colors={colors}
            emailRecovered={emailRecovered}
            setShowRecoverEmailModal={setShowRecoverEmailModal}
            userEmail={userEmail}
          />
        }
      />
      <Modal
        hideCloseButton
        openWithoutTrigger={actionCodeError.message}
        modalContent={
          <ActionCodeError
            colors={colors}
            actionCodeError={actionCodeError}
            setActionCodeError={setActionCodeError}
          />
        }
      />
    </>
  );
};
