import React, { useContext } from 'react';

import styled from 'styled-components';
import { LocalContext } from 'context';

// import { WonkyAvocado } from 'assets/svgs';
import { breakpoints } from 'styles';

const HubHeaderBackground = ({ colors }) => {
  const { theme } = useContext(LocalContext);



  return (
    <Background $theme={theme} colors={colors} />
  );
};

const Background = styled.div`
  background-color: ${({ $theme }) => $theme.bgLight};
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
`;



export default HubHeaderBackground;
