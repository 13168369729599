/* eslint-disable */

import React, { useContext, useState, useEffect } from 'react';
import { AnswerPollModal, LoadingSpinner } from 'components';
import styled from 'styled-components';
import { FirebaseContext, LocalContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { fadeInAndOutVariants } from 'styles';

const UserPanel = () => {
  const { firebase } = useContext(FirebaseContext);
  const [isPollLoading, setIsPollLoading] = useState(true);
  const [isAnswerPollModalOpen, setIsAnswerPollModalOpen] = useState(false);
  const [currentlyShownPoll, setCurrentlyShownPoll] = useState(false);
  const { selectedEvent } = useContext(LocalContext);

  useEffect(() => {
    let unsubscribeFromPolls;

    if (firebase) {
      unsubscribeFromPolls = firebase.subscribeToPolls({
        eventName: selectedEvent.name,
        onSnapshot: (snapshot) => {
          if (snapshot.exists()) {
            const polls = [];

            snapshot.forEach((child) => {
              polls.push({
                pid: child.key,
                ...child.val()
              });
            });

            const _currentlyShownPoll = polls.find((poll) => poll.showPoll);

            if (_currentlyShownPoll) {
              setCurrentlyShownPoll(_currentlyShownPoll);
              setIsAnswerPollModalOpen(true);
              setIsPollLoading(false);
            } else {
              setIsAnswerPollModalOpen(false);
              setIsPollLoading(false);
            }
          } else if (!snapshot.exists()) {
            setIsAnswerPollModalOpen(false);
            setIsPollLoading(false);
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromPolls) {
        unsubscribeFromPolls.off();
      }
    };
  }, [firebase]);

  return (
    <>
      <AnimatePresence>
        {!isPollLoading && !isAnswerPollModalOpen && (
          <motion.p
            style={{ fontStyle: 'italic', fontWeight: '300' }}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={fadeInAndOutVariants()}>
            No Active Polls At The Moment
          </motion.p>
        )}
      </AnimatePresence>
      {isPollLoading ? (
        <LoadingSpinnerWrapper>
          <LoadingSpinner style={{ width: '6rem', color: selectedEvent.colors.secondary }} />
        </LoadingSpinnerWrapper>
      ) : (
        <AnswerPollModal
          colors={selectedEvent.colors}
          poll={currentlyShownPoll}
          isAnswerPollModalOpen={isAnswerPollModalOpen}
          setIsAnswerPollModalOpen={setIsAnswerPollModalOpen}
        />
      )}
    </>
  );
};

const LoadingSpinnerWrapper = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  > svg {
    margin-bottom: 0.9rem;

    @media screen and (min-width: 410px) {
      margin-bottom: 1.125rem;
    }

    @media screen and (min-width: 1150px) and (max-width: 1365px) {
      margin-bottom: 0.9rem;
    }

    @media screen and (min-width: 1366px) {
      margin-bottom: 1.125rem;
    }
  }
`;

export default UserPanel;
