import React from 'react';
import styled, { css } from 'styled-components';
import { ActiveOrClosedPoll } from 'components';
import { AnimatePresence, motion } from 'framer-motion';
import { fadeInAndOutAndAnimateHeightVariants } from 'styles';

const ActiveAndClosedPolls = ({
  activePolls,
  closedPolls,
  selectedPoll,
  setSelectedPoll,
  currentlyShownPollPid,
  setCurrentlyShownPollPid,
  setEditPoll,
  setIsCreateOrEditPollModalOpen
}) => (
  <AnimatePresence>
    {(activePolls.length || closedPolls.length) && (
      <Wrapper
        activePolls={activePolls}
        closedPolls={closedPolls}
        initial="initial"
        animate="animate"
        exit="exit"
        variants={fadeInAndOutAndAnimateHeightVariants()}>
        <AnimatePresence>
          {activePolls.length && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={fadeInAndOutAndAnimateHeightVariants()}>
              <h4>Active Polls</h4>
              <AnimatePresence initial={false}>
                {activePolls.map((activePoll) => (
                  <ActiveOrClosedPoll
                    key={activePoll.pid}
                    poll={activePoll}
                    selectedPoll={selectedPoll}
                    setSelectedPoll={setSelectedPoll}
                    currentlyShownPollPid={currentlyShownPollPid}
                    setCurrentlyShownPollPid={setCurrentlyShownPollPid}
                    setEditPoll={setEditPoll}
                    setIsCreateOrEditPollModalOpen={setIsCreateOrEditPollModalOpen}
                  />
                ))}
              </AnimatePresence>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {closedPolls.length && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={fadeInAndOutAndAnimateHeightVariants()}>
              <h4>Closed Polls</h4>
              <AnimatePresence initial={false}>
                {closedPolls.map((closedPoll) => (
                  <ActiveOrClosedPoll
                    key={closedPoll.pid}
                    poll={closedPoll}
                    selectedPoll={selectedPoll}
                    setSelectedPoll={setSelectedPoll}
                  />
                ))}
              </AnimatePresence>
            </motion.div>
          )}
        </AnimatePresence>
      </Wrapper>
    )}
  </AnimatePresence>
);

const Wrapper = styled(motion.section).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  border-top: 0.063rem solid #bdbdbd;
  flex-grow: 1;
  margin-top: 1.25rem;
  overflow-x: hidden;

  ${({ activePolls, closedPolls }) =>
    activePolls.length + closedPolls.length > 3 &&
    css`
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0.75rem;
      }
      &::-webkit-scrollbar-track {
        background-color: rgba(196, 196, 196, 0.2);
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(196, 196, 196, 0.8);
        border-radius: 1.25rem;
      }
    `};

  ${({ activePolls }) => css`
    > div:nth-child(2) {
      ${activePolls.length && 'border-top: 0.063rem solid #bdbdbd'};
    }
  `};

  h4 {
    color: #c4c4d4;
    font-size: 0.75rem;
    font-weight: 400;
    margin-top: 0.9em;
    margin-left: 2.2em;
  }
`;

export default ActiveAndClosedPolls;
